export const domainTypes = {
  ANGENCY: 'Banco-Agencia',
  BANK: 'Banco',
  CAPACITY: 'Lotacao',
  CITY: 'Cidade',
  CIVIL_STATUS: 'Estado Civil',
  CONSULTANT: 'Consultor',
  FLAGLIST: 'FlagList',
  GENDER: 'Sexo',
  ISSUING_AUTHORITY: 'Orgao-Emissor',
  MODALITY: 'Modalidade-Contrato',
  NATIONALITY: 'Nacionalidade',
  STATE: 'UF',
  TYPE_CHECKING_ACCOUNT: 'Sexo',
  WORK_SCHEDULE: 'Horario-Trabalho',
  SALE: 'Vendas',
  SELLER: 'Vendedor',
  PROJECT_BILLING: 'ID_PROJETO_FATURAMENTO',
};

export const fieldTypes = {
  CHECKBOX: 'CheckBox',
  CURRENCY: 'Currency',
  DATE: 'Date',
  MASKED: 'MaskedFormControl',
  NUMERIC: 'number',
  SELECT: 'Select',
  TEXT: 'Text',
  TEXT_AREA: 'textarea',
  TEXT_EDITOR: 'TextEditor',
};

export const formMode = {
  EDIT: 'Editar',
  REGISTER: 'Cadastro',
  VIEW: 'Consulta',
};

export const modalTypes = {
  DELETE: 'Remover apontamento',
  DELETE_EXTRA_HOUR: 'Remover hora extra',
  EDIT: 'Editar',
  EXTRA_HOUR: 'Horas Adicionais',
  REPLICATE: 'Replicar Horas',
};

export const serviceControllers = {
	BANK: "/Bank",
	CAPACITY: "/Capacity",
	CLIENT: "/Client",
	CLIENT_MANAGER: "/ClientManager",
	CLIENT_RETENTION: "/ClientRetention",
	CLIENT_DEPARTMENT: "/ClientDepartment",
	CLIENT_DEPART_MANAGER: "/ClientDepartManager",
	COLLABORATOR: "/Collaborator",
	COLLABORATOR_TOKEN: "/CollaboratorToken",
	CLOSING: "/Closing",
	DASHBOARD: "/Dashboard",
	DOMAIN: "/Domain",
	DOMAIN_OWN: "/DomainOwn",
	HOLIDAY: "/Holiday",
	MENU: "/Menu",
	PROFILE: "/Profile",
	PROJECT: "/Project",
	PROJECT_BILLING: "/ProjectBilling",
	REGISTER: "/Register",
	REMUNERATION: "/Remuneration",
	SALES_ORDER: "/SalesOrder",
	TIMESHEET: "/Timesheet",
	USER: "/User",
	VACATION: "/Vacation",
	PROCESS: "/WorkProcess",
	PROCESS_LOG: "/WorkProcessLog",
	ORCHESTATOR: "/Orchestrator",
};

export const orderByDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};
