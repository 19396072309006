export interface Data {
  direction?: string;
  field?: any[];
  idDataset: string;
  orderField?: string;
  pageLength?: number;
  pageNumber?: number;
  records?: number;
}
export interface Dataset {
  data?: Data[];
}

export interface ListRequest {
  dataset?: Dataset;
  parameter: any;
  return?: any;
  result?: any;
  filter: any;
}

export const RequestParamsModelDefault: ListRequest = {
  parameter: {},
  return: null,
  result: null,
  filter: {
    field: [],
  },
  dataset: {
    data: [
      {
        direction: '',
        idDataset: '',
        orderField: '',
        pageLength: 50,
        pageNumber: 1,
        records: 0,
      },
    ],
  },
};

export const RequestParamsModel = (
  idDataset: string,
  direction: string = '',
  orderField: string = ''
): ListRequest => {
  return {
		parameter: {},
		return: null,
		result: null,
		filter: {
			field: [],
		},
		dataset: {
			data: [
				{
					direction: direction,
					idDataset: idDataset,
					orderField: orderField,
					pageLength: 50,
					pageNumber: 1,
					records: 0,
				},
			],
		},
	};
};
