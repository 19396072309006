export const Translations = {
	en_US: `locales/en_US/translation.json`,
	pt_BR: `locales/pt_BR/translation.json`,
	pt: `locales/pt_BR/translation.json`,
};

export const TranslationsOptions = [
	{ name: "English - US", shortName: "en-US" },
	{ name: "Português - Brasil", shortName: "pt-BR" },
	{ name: "Português", shortName: "pt" },
];
