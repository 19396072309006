import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../components/Loading';
import { ListComponent } from '../components/ListComponent';
import { FilterAndListBuilder, fetchMoreData } from '../util/listFunctions';
import { ConfirmationModal } from '../components/ConfirmationModal';
import { RequestParamsModel } from '../models/requestParams.model';
import BaseService from '../services/base.service';
import { ToastService } from '../services/toast.service';

const ListPageComponent = ({
  idDataset,
  serviceController,
  legend,
  selectedItem,
  setSelectedItem,
  buttons,
  referenceColumn,
  useIndex = false,
  customSelectFunction = null,
  delParams = null,
  deleteSuccessMessage = 'Excluído com sucesso!',
  showModal = false,
  setShowModal = null,
  byId = null,
  fieldId = null,
  //usar referências externas apenas se houver necessidade de trabalhar com elas na pagina que chama o componente
  externalDataSet = null,
  externalDataList = null,
  setExternalDataSet = null,
  setExternalDataList = null,
  selecionarTodos = null,
  toggleSelecionarTodos = null,
  setUltimoSelecionado = null,
  ultimoSelecionado = null,
  orderable = true,
}) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: any) => state.filtersToSearch);
  const [dataList, setDataList] = useState({ items: [] });
  const [dataset, setDataset] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [gridColumns, setGridColumns] = useState([]);
  const orderedBy = useSelector((state: any) => state.orderBy);

  const getList = useCallback(
    async (data, isFilter = false, selectAll: boolean = false) => {
      setLoading(true);

      FilterAndListBuilder(
        serviceController,
        data,
        filters,
        setExternalDataList ?? setDataList,
        externalDataList ?? dataList,
        setExternalDataSet ?? setDataset,
        setGridColumns,
        isFilter,
        dispatch,
        selectAll,
        setSelectedItem,
        byId,
        fieldId ?? referenceColumn
      );

      setLoading(false);
    },
    [
      serviceController,
      filters,
      setExternalDataList,
      externalDataList,
      dataList,
      setExternalDataSet,
      dispatch,
      setSelectedItem,
    ]
  );

  const selectItem = (index: number) => {
    setSelectedItem(selectedItem === index || index);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = async () => {
    try {
      
      var response:any = await BaseService.del(serviceController, {id: delParams});
      if (response?.return?.success === true) {
          setSelectedItem(null);
          setShowModal(false);
          ToastService.success(deleteSuccessMessage);
          getList(RequestParamsModel(idDataset), true);
        } else {
          ToastService.error('Erro ao excluir!');
        }
     
    } catch (error) {
      ToastService.error('Erro ao excluir!');
    }
  };

  useEffect(() => {
    setSelectedItem(toggleSelecionarTodos ? [] : null);
    getList(RequestParamsModel(idDataset, orderedBy?.direction, orderedBy?.column), true);
  }, [filters, orderedBy]);

  return (
    <>
      {isLoading && <Loading />}
      <ListComponent
        idDataset={idDataset}
        legend={legend}
        itemList={externalDataList ?? dataList}
        fetchMoreData={() => {
          fetchMoreData(externalDataSet ?? dataset, getList, idDataset);
        }}
        dataset={externalDataSet ?? dataset}
        gridColumns={gridColumns}
        buttons={buttons}
        selectItem={customSelectFunction ?? selectItem}
        selectedItems={selectedItem}
        referenceColumn={referenceColumn}
        useIndex={useIndex}
        //Parametros para telas com multipla seleção
        selecionarTodos={selecionarTodos}
        toggleSelecionarTodos={toggleSelecionarTodos}
        setUltimoSelecionado={setUltimoSelecionado}
        setItensSelecionados={setSelectedItem}
        ultimoSelecionado={ultimoSelecionado}
        orderable={orderable}
        order={orderedBy}
        onColumnOrdered={(column, direction) => {
          dispatch({
            type: 'ORDER_BY',
            orderBy: {
              column: column,
              direction: direction,
            },
          });
        }}
      />
      <ConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
      />
    </>
  );
};

export default ListPageComponent;
