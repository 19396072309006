import { useEffect, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import SystemRoutes from './routes';
import { store, persistor } from './store';

import './scss/styles.scss';
import './scss/connect-font.css';

const App = () => {
  useEffect(() => {
    localStorage.setItem('Idiom', 'pt-BR');
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback="loading">
          <ToastContainer style={{ fontSize: 'var(--default-font-size)' }} />
          <SystemRoutes />
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;
