import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteWithSidebar, RouteWithSiteHeader } from '../components/RouteWithBars';

import { UserList } from '../containers/user/list';
import { UserRegister } from '../containers/user/form/register';

import NotFound from '../containers/notFound';
import { HomeList } from '../containers/home/list';

import { StoreModel } from '../models/store.model';

// Novas telas traduzidas do PHP
import MainPage from '../containers/main/index';

import Unauthorized from '../containers/unauthorized';
import { addLeadingSlash, removeTrailingSlash } from '../util';

import ResetPassword from '../containers/main/resetPassword';
import { entityConfig } from '../util/entityConfig';

const authenticatedHomePage = '/dash';

const SystemRoutes = () => {
  return (
		<>
			<Routes>
				<Route path="/404" element={<NotFound />} />
				<Route path="/401" element={<Unauthorized />} />
				<Route path="/" element={<MainPage />} />
				<Route path="/:login" element={<MainPage />} />
				<Route path="/Home" element={<MainPage />} />
				<Route path="/ResetarSenha/:hash" element={<ResetPassword />} />
				<Route
					path={entityConfig.USER.rootUrl}
					element={<RouteWithSidebar component={UserList} />}
				/>
				<Route
					path="/Usuario/Cadastro"
					element={<RouteWithSidebar component={UserRegister} />}
				/>
				<Route
					path={`${entityConfig.USER.rootUrl}/:mode/:id`}
					element={<RouteWithSidebar component={UserRegister} />}
				/>
				<Route element={<PrivateRouteWithSidebar />}>
					<Route
						path={authenticatedHomePage}
						element={<RouteWithSidebar component={HomeList} />}
					/>
					{/* <Route
            path={entityConfig.USER.rootUrl}
            element={<RouteWithSidebar component={UserList} />}
          />
          <Route path="/Usuario/Cadastro" element={<RouteWithSidebar component={UserRegister} />} />
          <Route
            path={`${entityConfig.USER.rootUrl}/:mode/:id`}
            element={<RouteWithSidebar component={UserRegister} />}
          /> */}
				</Route>
				<Route path="*" element={<Navigate to="/404" />} />
			</Routes>
		</>
	);
};

const PrivateRouteWithSidebar = () => {
  const authentication = useSelector((state: StoreModel) => state.authentication);
  const companyMenu = useSelector((state: StoreModel) => state.companyMenu);

  const location = useLocation();
  const normalizedLocation = removeTrailingSlash(location.pathname.toLowerCase());

  if (!authentication?.token) {
    return <Navigate to="/" />;
  }

  let menuList = companyMenu;

  if (companyMenu === undefined || companyMenu.length === 0) menuList = authentication?.menu;

  if (
    normalizedLocation !== authenticatedHomePage &&
    !menuList?.some(
      (menu) =>
        menu.urlTarget !== null &&
        normalizedLocation.startsWith(
          addLeadingSlash(removeTrailingSlash(menu.urlTarget.toLowerCase()))
        )
    )
  ) {
    return <Navigate to="/401" />;
  }

  return <Outlet />;
};

export default SystemRoutes;
