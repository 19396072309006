import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	FaRegEdit,
	FaEdit,
	FaRegEye,
	FaEye,
	FaUserPlus,
	FaFileExcel,
	FaRegFileExcel,
	FaTrash,
	FaTrashAlt,
} from "react-icons/fa";
import { ButtonModel } from "../../models/components/button.model";
import { formMode } from "../../util/enumerators";
import ListPageComponent from "../../components/ListPageComponent";
import { ExportFunctions } from "../../util/ExportFunctions";
import { RequestParamsModel } from "../../models/requestParams.model";
import { entityConfig } from "../../util/entityConfig";
import { useDispatch } from "react-redux";

interface IUserList {
	selectedItem: any;
	setSelectedItem: any;
	customSelectFunction: any;
	selecionarTodos: any;
	toggleSelecionarTodos: any;
	setUltimoSelecionado: any;
	ultimoSelecionado: any;
	userList: any;
	setUserList: any;
}

export const UserList = (props: IUserList = undefined) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectedItem, setSelectedItem] = useState<any>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [filtersSet, setFiltersSet] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const entity = entityConfig.USER;

	const buttons: ButtonModel[] = [
		{
			onClick: () => {
				navigate(`${entity.rootUrl}/${formMode.VIEW}/${selectedItem}`);
			},
			text: "Visualizar",
			disabled: !Number.isInteger(selectedItem),
			icon: (
				<>
					<FaRegEye className="fa-icon" />
					<FaEye className="fa-icon-hover" />
				</>
			),
			tooltip: "Visualizar dados do usuário selecionado",
		},
		{
			onClick: () => {
				navigate(`${entity.rootUrl}/${formMode.EDIT}/${selectedItem}`);
			},
			text: "Editar",
			disabled: !Number.isInteger(selectedItem),
			icon: (
				<>
					<FaRegEdit className="fa-icon" />
					<FaEdit className="fa-icon-hover" />
				</>
			),
			tooltip: "Editar usuário selecionado",
		},
		{
			onClick: () => {
				setShowModal(true);
			},
			text: "Excluir",
			disabled: !Number.isInteger(selectedItem),
			icon: (
				<>
					<FaTrashAlt className="fa-icon" />
					<FaTrash className="fa-icon-hover" />
				</>
			),
			tooltip: "Excluir usuário selecionada",
		},
		{
			onClick: () => {
				navigate(`${entity.rootUrl}/${formMode.REGISTER}`);
			},
			text: "Novo",
			disabled: false,
			icon: <FaUserPlus className="fa-icon-color-green" />,
			tooltip: "Adicionar novo usuário",
		},
		{
			onClick: async () => {
				setLoading(true);
				await ExportFunctions.downloadExcel(
					entity.controller,
					RequestParamsModel(entity.idDataset),
					entity.idDataset
				);

				setLoading(false);
			},
			text: "Exportar todos",
			disabled: isLoading,
			icon: (
				<>
					<FaRegFileExcel className="fa-icon" />
					<FaFileExcel className="fa-icon-hover" />
				</>
			),
			tooltip: "Exportar dados para Excel",
		},
	];

	const delParams = () => {
		return {
			id: selectedItem,
		};
	};

	useEffect(() => {
		
		dispatch({
			type: "DISABLE_TOP_FILTER",
			isTopFilterDisabled: false,
		});
		
		dispatch({
			type: "SEARCH_FILTERS",
			filtersToSearch: [],
		});

		dispatch({
			type: "ORDER_BY",
			orderBy: {
				column: "",
				direction: "",
			},
		});
		setFiltersSet(true);
	}, []);

	if (!filtersSet) return <div></div>;

	if (typeof props.setSelectedItem !== "undefined") {
		return (
			<>
				<ListPageComponent
					idDataset={entity.idDataset}
					serviceController={entity.controller}
					legend={entity.listTitle}
					customSelectFunction={props.customSelectFunction}
					selectedItem={props.selectedItem}
					setSelectedItem={props.setSelectedItem}
					selecionarTodos={props.selecionarTodos}
					toggleSelecionarTodos={props.toggleSelecionarTodos}
					setUltimoSelecionado={props.setUltimoSelecionado}
					ultimoSelecionado={props.ultimoSelecionado}
					buttons={buttons}
					referenceColumn={entity.itemKey}
					externalDataList={props.userList}
					setExternalDataList={props.setUserList}
					showModal={showModal}
					setShowModal={() => {
						setShowModal(false);
					}}
					delParams={selectedItem}
				/>
			</>
		);
	}

	return (
		<>
			<ListPageComponent
				idDataset={entity.idDataset}
				serviceController={entity.controller}
				legend={entity.listTitle}
				selectedItem={selectedItem}
				setSelectedItem={setSelectedItem}
				buttons={buttons}
				referenceColumn={entity.itemKey}
				showModal={showModal}
				setShowModal={() => {
					setShowModal(false);
				}}
				delParams={selectedItem}
			/>
		</>
	);
};
