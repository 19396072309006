const Fieldset = (props) => {
  return (
    <>
      <fieldset
        style={{
          backgroundColor: props.background,
          overflowX: props.overflowX,
          maxHeight: props.maxHeight,
        }}
        className={props.className}
        {...props}
      >
        {props.legend != null && props.legend != '' && <legend>{props.legend}</legend>}
        {props.children}
      </fieldset>
    </>
  );
};

export default Fieldset;
