import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// Componentes tela, icones e estilos
import {
	FaRegEye,
	FaEye,
	FaArrowLeft,
	FaArrowRight,
	FaFileCsv,
	FaRegArrowAltCircleRight,
	FaRegArrowAltCircleLeft,
	FaTrashAlt,
	FaRecycle,
	FaCheck,
	FaRegPlayCircle,
	FaPlayCircle,
	FaRegStopCircle,
	FaStopCircle,
	FaRegClock,
	FaClock,
	FaRegListAlt,
	FaListAlt,
} from "react-icons/fa";
import "../../scss/styles.scss";
import { Button, Form, ListGroup, Toast } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Modal from "react-bootstrap/Modal";


// Date Range
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";

// Services
import { ToastService } from "../../services/toast.service";
import DashboardService from "../../services/dashboard.service";
import { ButtonModel } from "../../models/components/button.model";
import { Header } from "../../components/Header";
import { Loading } from "../../components/Loading";
import { CheckBoxList } from "../../components/CheckBoxList";
import { toast } from "react-toastify";

export const HomeList = () => {
	const dispatch = useDispatch();
	const filters = useSelector((state: any) => state.filtersToSearch);
	const refreshList = useSelector((state: any) => state.refreshList);
	const filtersToSearch = useSelector((state: any) => state.filtersToSearch);
	const [loading, setLoading] = useState(false);
	const [dashboardData, setDasboardData] = useState({
		detailedDashboardList: null,
		fullDetailedDashboardList: null,
		message: "",
		sumaryzedDashboardList: null,
	});
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [selectedRowIndex, setSelectedRowIndex] = useState<Number>(-1);
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState<any[]>([]);

	const [filtersSet, setFiltersSet] = useState(false);
	const [defaultFilterSet, setDefaultFilterSet] = useState(false);
	const [defaultFilters, setDefaultFilters] = useState<any[]>([]);
	const date = new Date();
	const [currentYear, setCurrentYear] = useState(date.getFullYear());
	const [currentMonth, setCurrentMonth] = useState(date.getMonth());
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const [modalWidth, setModalWidth] = useState("90vw");
	const [selectedStep, setSelectedStep] = useState<any>(null);

	const [showModalReprocessBatch, setShowModalReprocessBatch] = useState({
			show: false,
			rowData: [],
			listStatus: [],
			selectedListStatus: []
	})

	const [questionModal, setQuestionModal] = useState({
		show: false,
		action: "none",
		question: "none",
		callback: null,
	});

	const [scheduleModal, setScheduleModal] = useState({
		show: false,
		inputFields: [],
		mapInputFields:[],
		selectedStep: "",
	});

	const [scheduleInputFields, setscheduleInputFields] = useState<any[]>([]);

	const [scheduleDates, setScheduleDates] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);

	const [imediateExecution, setImediateExecution] = useState(false);

	const getInitialTime = () => {
		const hours = "04";
		const minutes = "00";
		return `${hours}:${minutes}`;
	};

	const getCurrentTime = () => {
		const now = new Date();
		const hours = String(now.getHours()).padStart(2, "0");
		const minutes = String(now.getMinutes()).padStart(2, "0");
		return `${hours}:${minutes}`;
	};

	const [scheduleTime, setScheduleTime] = useState(getInitialTime());

	const buttons: ButtonModel[] = [
		{
			onClick: () => openModalReprocessBatch(),
			text: "Reprocessar em lote",
			disabled: selectedRowIndex == null || selectedRowIndex == -1,
			icon: (
				<>
					<FaRegListAlt className="fa-icon" />
					<FaListAlt className="fa-icon-hover" />
				</>
			),
		},
		{
			onClick: () => executeStepScheduling(),
			text: "Agendar etapa",
			disabled: selectedRowIndex == null || selectedRowIndex == -1,
			icon: (
				<>
					<FaRegClock className="fa-icon" />
					<FaClock className="fa-icon-hover" />
				</>
			),
		},
		{
			onClick: () => executeProcessConfirmation(),
			text: `${
				selectedStep && selectedStep.inExecution
					? "Parar etapa"
					: "Iniciar etapa"
			}`,
			disabled: selectedRowIndex == null || selectedRowIndex == -1,
			icon: (
				<>
					{selectedStep && selectedStep.inExecution ? (
						<>
							<FaRegStopCircle className="fa-icon" />
							<FaStopCircle className="fa-icon-hover" />
						</>
					) : (
						<>
							<FaRegPlayCircle className="fa-icon" />
							<FaPlayCircle className="fa-icon-hover" />
						</>
					)}
				</>
			),
		},
		// {
		// 	onClick: async () => executeProcessConfirmation("iniciar"),
		// 	text: "Iniciar etapa",
		// 	disabled: selectedRowIndex == null || selectedRowIndex == -1,
		// 	icon: (
		// 		<>
		// 			<FaRegPlayCircle className="fa-icon" />
		// 			<FaPlayCircle className="fa-icon-hover" />
		// 		</>
		// 	),
		// },
		{
			onClick: () => visualizarModal(),
			text: "Visualizar Detalhes",
			disabled: selectedRow == null,
			icon: (
				<>
					<FaRegEye className="fa-icon" />
					<FaEye className="fa-icon-hover" />
				</>
			),
		},
	];

	const renderHead = () => {
		let colSpan = 3;
		if (dashboardData?.sumaryzedDashboardList) {
			colSpan += dashboardData?.sumaryzedDashboardList?.length;

			return (
				<th
					key={"head"}
					colSpan={colSpan}
					scope="col"
					style={{ textAlign: "center", zIndex: 0 }}
				>
					Etapas dos processos
				</th>
			);
		}
	};

	const renderDays = () => {
		if (dashboardData?.sumaryzedDashboardList) {
			try {
				let days = dashboardData?.sumaryzedDashboardList.map((item) => {
					var date = new Date(item.date);
					return {
						day: String(date.getDate()).padStart(2, "0"),
						week: item.diaSemana,
					};
				});

				return (
					<>
						{days.map((day, index) => (
							<th
								key={index}
								style={{ zIndex: 0, textAlign: "center", fontSize: "7pt" }}
							>
								<span style={{ display: "block" }}>{day.day}</span>
								<span style={{ display: "block" }}>{day.week}</span>
							</th>
						))}
					</>
				);
			} catch (error) {
				console.error(error);
				return null;
			}
		}
		return;
	};

	const isSelected = (index) => {
		return selectedRowIndex == index;
	};

	const handleCheckRow = (index, item) => {
		
		if (isSelected(index)) {
			setSelectedRow(null);
			setSelectedRowIndex(-1);
			setSelectedStep(null);
		} else {
			setSelectedRowIndex(index);

			setSelectedStep({
				id: item.stepComponentId,
				inExecution: item.inExecution,
				allFields: item,
			});
			setSelectedRow(null);
		}
	};

	const renderRows = () => {
		if (dashboardData?.detailedDashboardList) {
			return (
				<>
					{dashboardData?.detailedDashboardList.map((item, index) => {
						return (
							<React.Fragment key={index}>
								<tr
									key={index}
									id={`table-tr-${index}`}
									style={{ cursor: "pointer" }}
									className={`${
										index % 2 === 0 ? "table-active" : ""
									} table-dashboard-row `}
									data-status={item.inExecution}
								>
									<td
										style={{
											padding: 0,
											width: "30px",
										}}
										onClick={(e) => {
											handleCheckRow(index, item);
										}}
									>
										<div
											className="line-check"
											style={{
												backgroundColor: `${
													item.inExecution == true ? "green" : "red"
												}`,
												width: isSelected(index) ? "30px" : 9,
											}}
										>
											{isSelected(index) && <FaCheck />}
										</div>
									</td>

									<td
										scope="row"
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											handleCheckRow(index, item);
										}}
									>
										{item.groupCode}
									</td>
									<td
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											handleCheckRow(index, item);
										}}
									>
										{item.stepDescription}
									</td>
									{/* <td style={{ textAlign: "center" }}>-</td> */}
									{renderStatus(item)}
								</tr>
							</React.Fragment>
						);
					})}
				</>
			);
		}
	};

	const renderTooltip = (props, description) => (
		<Tooltip id="button-tooltip" {...props}>
			{description ? description : "Sem Status"}
		</Tooltip>
	);

	const handleSelectRow = (row) => {
		if (row.historicComponentId == selectedRow?.historicComponentId) {
			setSelectedRow(null);
		} else {
			setSelectedRow(row);
		}
	};

	const renderStatus = (row) => {
		if (
			dashboardData?.fullDetailedDashboardList &&
			dashboardData?.detailedDashboardList
		) {
			let rowData = dashboardData?.fullDetailedDashboardList.filter(
				(x) =>
					x.groupCompnentId == row.groupCompnentId &&
					x.stepComponentId == row.stepComponentId
			);

			return (
				<>
					{rowData.map((row, index) => (
						<td
							key={index}
							onClick={() => {
								if (row.historicComponentId && row.historicComponentId != 0) {
									setSelectedRowIndex(-1);
									handleSelectRow(row);
								} else {
									setSelectedRowIndex(-1);
									setSelectedRow(null);
								}
							}}
							style={{
								textAlign: "center",
								cursor: row.historicComponentId != 0 ? "pointer" : "default",
							}}
							className={
								row.historicComponentId == selectedRow?.historicComponentId
									? "selectedStatus"
									: ""
							}
							data-status={row.statusDescription}
							id={`td-${index}`}
						>
							{row.historicComponentId != 0 ? (
								<OverlayTrigger
									delay={{ show: 250, hide: 400 }}
									overlay={(props) =>
										renderTooltip(props, row.statusDescription)
									}
								>
									<i
										className="fa fa-circle"
										aria-hidden="true"
										style={{ color: row.statusColor }}
									></i>
								</OverlayTrigger>
							) : (
								<i className="fa fa-circle hidden" aria-hidden="true"></i>
							)}
						</td>
					))}
				</>
			);
		}
	};

	const renderDetail = (detailType, detail, date) => {
		switch (detailType) {
			case 1:
				return (
					<>
						<i
							className="fa fa-check"
							aria-hidden="true"
							style={{
								color: "green",
								paddingRight: "2px",
								width: 15,
								height: 15,
							}}
						></i>
						<span style={{ color: "green" }}>
							{moment(date).format("DD/MM/YYYY HH:mm:ss")} {" - "} {detail}
						</span>
					</>
				);
			case 2:
				return (
					<>
						<i
							className="fa fa-exclamation-triangle"
							aria-hidden="true"
							style={{
								color: "orange",
								paddingRight: "2px",
								width: 15,
								height: 15,
							}}
						></i>
						<span style={{ color: "orange" }}>
							{" "}
							{moment(date).format("DD/MM/YYYY HH:mm:ss")} {" - "}
							{detail}
						</span>
					</>
				);
			case 3:
				return (
					<>
						<i
							className="fa fa-exclamation-circle"
							aria-hidden="true"
							style={{
								color: "red",
								paddingRight: "2px",
								width: 15,
								height: 15,
							}}
						></i>
						<span style={{ color: "red" }}>
							{" "}
							{moment(date).format("DD/MM/YYYY HH:mm")} {" - "}
							{detail}
						</span>
					</>
				);
			default:
				return "";
		}
	};

	const fetchData = async (dateValue, grupo, step, situation) => {
		setLoading(true);

		try {
			const response: any = await DashboardService.getDashBoard(
				dateValue,
				grupo,
				step,
				situation
			);

			setDasboardData(response?.result);

			if (response?.filter?.field) {
				const copiaDeFiltros = JSON.parse(
					JSON.stringify(response?.filter?.field)
				);
				const copiaDeFiltrosDefault = JSON.parse(
					JSON.stringify(response?.filter?.field)
				);

				var filters = copiaDeFiltros.map((item) => {
					item["showFilter"] = true;
					item["defaultFilterValue"] = item.value;
					item["value"] = item.description;
					return item;
				});

				dispatch({
					type: "LOAD_FILTERS",
					filtersList: filters,
				});

				setDefaultFilters(filters);

				if (!defaultFilterSet) {
					let objectFilterDefault = copiaDeFiltrosDefault?.filter(
						(x) => x.value != null
					);

					let newFiltersList = objectFilterDefault.map((element) => {
						return {
							id: element?.id,
							filterType: element?.description,
							filterValue: element?.value,
							filterMask: element?.mask,
							filterDataType: element?.type,
							isDefault: element.value != null,
							defaultFilterValue: element.value != null,
							filterDisplayValue: formatDateMMYYYY(element?.value),
							filterDomainOptions: element.valueDomain,
						};
					});

					dispatch({
						type: "SEARCH_FILTERS", //opcoes de filtro - @
						filtersToSearch: newFiltersList,
					});

					setDefaultFilterSet(true);
				}
			} else if (defaultFilters.length > 0) {
				dispatch({
					type: "LOAD_FILTERS",
					filtersList: defaultFilters,
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const formatDateMMYYYY = (valor: string) => {
		let [ano, mes] = valor.split("-").slice(0, 2);

		// Aplicar a máscara MM/yyyy
		return `${mes}/${ano}`;
	};

	const formatDateTime = (date) => {
		// Obter componentes da data
		var day = padZero(date.getDate());
		var month = padZero(date.getMonth() + 1); // Os meses são base 0, então adicionamos 1
		var year = date.getFullYear();

		// Formatar a data e hora
		var formattedDateTime = day + "/" + month + "/" + year;

		return formattedDateTime;
	};

	const formatTime = (valor: Date) =>{
		if (valor !== null && valor !== undefined) {
			const date = new Date(valor);
			
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${hours}:${minutes}:${seconds}`;
		}
		return ' - '; 
	}

	// Função para adicionar um zero à esquerda se o número for menor que 10
	const padZero = (num) => {
		return (num < 10 ? "0" : "") + num;
	};

	const executeProcess = async () => {
		if (selectedStep) {
			closeQuestionModal();
			setLoading(true);

			try {
				var response = await DashboardService.executeProcess(selectedStep.id);

				setSelectedRow(null);
				setSelectedRowIndex(-1);
				setSelectedStep(null);

				handleFilters();

				setTimeout(() => {
					ToastService.success("Processo realizado!");
				}, 300);
			} catch (error) {
			} finally {
				setTimeout(() => {
					setLoading(false);
				}, 700);
			}
		}
	};

	const executeProcessConfirmation = () => {
		let action = selectedStep.inExecution ? "parar" : "iniciar";

		setQuestionModal({
			...questionModal,
			show: true,
			action,
			question: `Deseja ${action} o processo da etapa?`,
			callback: executeProcess,
		});
	};

	function extractFields(xml) {
		// Expressão regular para encontrar os nomes das tags dentro de <PARAMETRO>
		const regex = /<(\w+)>[^<]*<\/\1>/g;
		let match;
		const fields = [];

		// Loop através de todas as correspondências da expressão regular
		while ((match = regex.exec(xml)) !== null) {
			// Adiciona o nome da tag ao array se ainda não estiver presente
			const tagName = match[1];
			if (!fields.includes(tagName)) {
				fields.push(tagName);
			}
		}

		return fields;
	}

	function extractValueFromFields(xmlString, fieldName) {
		 // Parse the XML string
		 const parser = new DOMParser();
		 const xmlDoc = parser.parseFromString(xmlString, "text/xml");
		 
		 // Find the element by tag name
		 const elements = xmlDoc.getElementsByTagName(fieldName);
		 
		 // Return the value of the first element found
		 if (elements.length > 0) {
			 return elements[0].textContent;
		 } else {
			 return null;
		 }
	}

	function extractFieldsWithRererences(xml) {
		// Parse o XML string para um objeto DOM
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xml, "text/xml");

		// Use querySelectorAll para encontrar todos os elementos com isDateReference="true"
		const dateReferenceElements = xmlDoc.querySelectorAll(
			'[isDateReference="true"]'
		);

		// Extraia o nome de cada elemento encontrado
		const fieldNames = Array.from(dateReferenceElements).map(
			(element) => element.tagName
		);
		return fieldNames;
	};

	const executeStepScheduling = () => {
		if (selectedStep) {
			var configs = selectedStep.allFields.configurations;

			var inputFields = [];

			if (configs) {
				var inputFields = extractFields(configs);

				var mapInputFields = inputFields.map((item) => {
					return { field: item, value: extractValueFromFields(configs, item) };
				});

				setscheduleInputFields(mapInputFields);
			}

			setScheduleModal({
				...scheduleModal,
				show: true,
				inputFields: inputFields,
				mapInputFields: mapInputFields,
				selectedStep: selectedStep.allFields.stepDescription,
			});
		}
	};

	const handleImediate = (e) => {
		if (e.target.checked) {
			setImediateExecution(true);
			setScheduleTime(getCurrentTime());
			// setScheduleDates([
			// 	{
			// 		startDate: new Date(),
			// 		endDate: new Date(),
			// 		key: "selection",
			// 	},
			// ]);
		} else {
			setImediateExecution(false);
			setScheduleTime(getInitialTime());
		}
	};

	const buttonSchedulingDisabled = () => {
		let existNullField =
			scheduleInputFields.findIndex((x) => x.value == null || x.value == "") >
			-1;
		if (existNullField && scheduleModal.inputFields.length > 0) {
			return true;
		} else return false;
	};

	const handleInputFields = (field: string, value: any) => {
		setscheduleInputFields((prevFields) => {
			const existingField = prevFields.find((f) => f.field === field);
			if (existingField) {
				return prevFields.map((f) => (f.field === field ? { ...f, value } : f));
			} else {
				return [...prevFields, { field, value }];
			}
		});
	};

	const saveScheduling = async () => {
		
		let parameters = scheduleInputFields.map((item) => {
			return { key: item.field, value: item.value };
		});

		closeScheduleModal();
		setLoading(true);
		try {
			// Extract hours and minutes from the time string
			const [hours, minutes] = scheduleTime.split(":").map(Number);

			// Get the original start and end dates
			const startDate = new Date(scheduleDates[0].startDate);
			const endDate = scheduleDates[0].endDate
				? new Date(scheduleDates[0].endDate)
				: null;

			// Update the start date with the time
			startDate.setHours(hours, minutes, 0, 0);
			const startDateFormatted = startDate.toISOString();

			// Update the end date with the time, if it exists
			let endDateFormatted = null;
			if (endDate) {
				endDate.setHours(hours, minutes, 0, 0);
				endDateFormatted = endDate.toISOString();
			}

			// Set DateReferences fields
			var configs = selectedStep.allFields.configurations;
			let references = extractFieldsWithRererences(configs);

			if (references && references?.length > 0) {
				let dateReference = startDate.toISOString().split("T")[0];

				let parametersReferences = references.map((item) => {
					return { key: item, value: dateReference, dateReference: true };
				});

				parameters = parameters.concat(parametersReferences);
			}

			var payload = {
				idComponent: Number(selectedStep.allFields.componentId),
				startDate: startDateFormatted,
				endDate: endDateFormatted,
				parameters: parameters,
				idGroupComponent: Number(selectedStep.allFields.groupCompnentId),
				idStepComponent: Number(selectedStep.allFields.stepComponentId),
				flgExecuteNow: imediateExecution
			};

			var response: any = await DashboardService.stepScheduling(
				JSON.stringify(payload)
			);

			if (response.return == null) {
				ToastService.info("Agendamento realizado!", null);

				setScheduleDates([
					{
						startDate: new Date(),
						endDate: new Date(),
						key: "selection",
					},
				]);

				handleFilters(); // Refaz os filtros e pesquisa
			} else ToastService.error("Erro ao realizar agendamento");
		} catch (error) {
			ToastService.error("Erro ao realizar agendamento");
		} finally {
			setLoading(false);
		}
	};

	const visualizarModal = async () => {
		setLoading(true);
		try {
			const response: any = await DashboardService.getHistoric(
				selectedRow.historicComponentId
			);
			if (
				response?.result &&
				response?.result?.dashboardHistoryList?.length > 0
			) {
				
				if (response?.result?.dashboardHistoryList)
					setModalData(response?.result?.dashboardHistoryList);
				setShowModal(true);
			} else {
				ToastService.info("Não há detalhes para este dia", null);
			}
		} catch {
		} finally {
			setLoading(false);
		}
	};

	const openModalReprocessBatch = () => {
		var row = dashboardData?.detailedDashboardList[selectedRowIndex as any];
	
		let rowData = dashboardData?.fullDetailedDashboardList.filter(
			(x) =>
				x.groupCompnentId == row.groupCompnentId &&
				x.stepComponentId == row.stepComponentId
		);

		var listStatus = [];
		rowData.forEach(row => {

			let statusDescription = row.statusDescription ? row.statusDescription : "Sem Status";
			if(row.historicComponentId && !listStatus.find(status => status.value == statusDescription)){

				listStatus.push({
					id: row.statusId,
					value: statusDescription,
					color: (row.statusColor ? row.statusColor : "#000"),
					count: 1,
					
				})
			} else {
				let found = listStatus.find(x => x.value == statusDescription);
				if(found){
					found.count++
				}
			}
		})

		listStatus = listStatus.map(x => {
			return {
				...x,
				extraComponent: <><i
									className="fa fa-circle"
									aria-hidden="true"
									style={{ color: x.color }}
								></i><span style={{fontWeight: 'bolder', fontSize: '12px'}}> - ({x.count})</span>
								
								</>
			}
		})
		
		  setShowModalReprocessBatch({
			show: true,
			rowData: rowData,
			listStatus: listStatus,
			selectedListStatus: []
		  })
		
	}

	const closeModalReprocessBatch = () => {
		setShowModalReprocessBatch({
			show: false,
			rowData: [],
			listStatus: [],
			selectedListStatus: []
		  })
	}

	const reprocessBatch = async () => {
		var row = dashboardData?.detailedDashboardList[selectedRowIndex as any];
	
		let rowData = dashboardData?.fullDetailedDashboardList.filter(
			(x) =>
				x.groupCompnentId == row.groupCompnentId &&
				x.stepComponentId == row.stepComponentId
		);

		let listReprocess = rowData.filter(row => {
			let statusDescription = row.statusDescription ? row.statusDescription : "Sem Status";
			return row.historicComponentId && showModalReprocessBatch.selectedListStatus.find(status => status.value == statusDescription)
	})

	
	listReprocess = listReprocess.map(x => {
		return { historicComponentId:x.historicComponentId };
	});

		var listRes = await DashboardService.reprocessBatch(listReprocess) as any;
		
		let quantSuccess = 0;
		let quantError = 0;

		listRes.forEach(result => {
			if(result.return.success){
				quantSuccess++;
			} 

			if(result.return.error){
				quantError++;
			}
		});
			toast.info(<>
			{quantSuccess > 0 &&<div>{`${quantSuccess} colocados na fila para reprocessamento`}</div>}
			{quantError > 0 && <><br />{`${quantError} processos falharam ao reprocessar`}</>}
			</>, { toastId: 'info-toast' });
	
		handleFilters();
		
		setShowModalReprocessBatch({
			show: false,
			rowData: [],
			listStatus: [],
			selectedListStatus: []
	});
		
	}

	const closeModal = () => {
		setSelectedRow(null);
		setModalData([]);
		setShowModal(false);
	};

	const closeQuestionModal = () => {
		setQuestionModal({ ...questionModal, show: false });
	};


	const showQuestionModal = (action: string) => {
		setQuestionModal({
			...questionModal,
			show: true,
			action: action,
			question: `Tem certeza que deseja realizar ${action}?`,
			callback: () => confirmQuestion(action),
		});
	};

	const closeScheduleModal = () => {
		setScheduleModal({
			...scheduleModal,
			show: false,
		});
	};

	const exportToCsv = () => {
		if (selectedRow) {
			setLoading(true);
			try {
				DashboardService.getCsv(selectedRow.historicComponentId);
			} catch {
			} finally {
				setLoading(false);
			}
		}
	};

	const GetMonthDescription = () => {
		// Array contendo os nomes dos meses
		var nomesMeses = [
			"Janeiro",
			"Fevereiro",
			"Março",
			"Abril",
			"Maio",
			"Junho",
			"Julho",
			"Agosto",
			"Setembro",
			"Outubro",
			"Novembro",
			"Dezembro",
		];

		// Obtém o índice do mês atual
		var indiceMesAtual = new Date(
			`${currentYear}-${currentMonth}-05`
		).getMonth();
		var nomeMesAtual = nomesMeses[indiceMesAtual];

		return nomeMesAtual;
	};

	const centeredButtons: ButtonModel[] = [
		{
			onClick: () => {
				setSelectedRow(null);
				setSelectedRowIndex(-1);
				setSelectedStep(null);

				let newMonth = currentMonth - 1;
				let newYear = currentYear;
				if (newMonth <= 0) {
					newMonth = 12;
					newYear = currentYear - 1;
					setCurrentYear(currentYear - 1);
				}

				setCurrentMonth(newMonth);
				let filterList = filters;
				const indexFilter = filterList.map((e) => e.id).indexOf("DATA_INICIAL");

				if (indexFilter >= 0) {
					filterList[indexFilter] = {
						id: "DATA_INICIAL",
						filterType: "@Data",
						filterValue:
							newYear +
							"-" +
							(newMonth < 10 ? "0" + newMonth : newMonth) +
							"-01",
						filterDisplayValue:
							(newMonth < 10 ? "0" + newMonth : newMonth) + "/" + newYear,
						filterMask: "11/1111",
						filterDataType: "data",
						filterDomainOptions: [],
					};
				}
				dispatch({
					type: "LOAD_FILTERS",
					filtersList: filterList,
				});
				handleFilters();
			},
			text: "Mês anterior",
			disabled: loading,
			icon: (
				<>
					<FaArrowLeft className="fa-icon" />
					<FaRegArrowAltCircleLeft className="fa-icon-hover" />
				</>
			),
		},
		{
			onClick: async () => {},
			text: `${GetMonthDescription()}/${currentYear}`,
			disabled: true,
			variant: "outline-primary",
			idText: "current-date-txt",
		},
		{
			onClick: () => {
				setSelectedRow(null);
				setSelectedRowIndex(-1);
				setSelectedStep(null);

				let newMonth = currentMonth + 1;
				let newYear = currentYear;
				if (newMonth > 12) {
					newMonth = 1;
					newYear = currentYear + 1;
					setCurrentYear(currentYear + 1);
				}

				setCurrentMonth(newMonth);
				let filterList = filters;
				const indexFilter = filterList.map((e) => e.id).indexOf("DATA_INICIAL");

				if (indexFilter >= 0) {
					filterList[indexFilter] = {
						id: "DATA_INICIAL",
						filterType: "@Data",
						filterValue:
							newYear +
							"-" +
							(newMonth < 10 ? "0" + newMonth : newMonth) +
							"-01",
						filterDisplayValue:
							(newMonth < 10 ? "0" + newMonth : newMonth) + "/" + newYear,
						filterMask: "11/1111",
						filterDataType: "data",
						filterDomainOptions: [],
					};
				}
				dispatch({
					type: "LOAD_FILTERS",
					filtersList: filterList,
				});
				handleFilters();
			},
			text: "Próximo mês",
			disabled: loading,
			icon: (
				<>
					<FaArrowRight className="fa-icon" />
					<FaRegArrowAltCircleRight className="fa-icon-hover" />
				</>
			),
		},
	];

	const confirmQuestion = async (action: string) => {
		setLoading(true);

		if (action == "reprocessamento") {
			try {
				await DashboardService.reprocessDetail(selectedRow.historicComponentId);
				ToastService.info("Reprocessamento realizado!", null);

				handleFilters(); // Refaz os filtros e pesquisa
			} catch {
			} finally {
				setLoading(false);
			}
		} else {
			try {
				await DashboardService.removeDetail(selectedRow.historicComponentId);
				ToastService.info("Exclusão realizada", null);

				handleFilters(); // Refaz os filtros e pesquisa
			} catch {
			} finally {
				setLoading(false);
			}
		}

		setQuestionModal({ ...questionModal, show: false });
		setShowModal(false);
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight);

			const windowWidth = window.innerWidth;
			const newModalWidth = windowWidth * 0.9; // Definir a largura da modal como 90% da largura da janela
			setModalWidth(`${newModalWidth}px`);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		dispatch({
			type: "DISABLE_TOP_FILTER",
			isTopFilterDisabled: false,
		});

		dispatch({
			type: "LOAD_FILTERS",
			filtersList: [],
		});

		dispatch({
			type: "SEARCH_FILTERS",
			filtersToSearch: [],
		});

		fetchData(null, null, null, null);

		setFiltersSet(true);
	}, []);

	const handleFilters = () => {
		let dataValue = null;
		let groupValue = null;
		let stepValue = null;
		let situationValue = null;

		if (filters?.length > 0) {
			const dataFilter = filters.filter((x) => x.id == "DATA_INICIAL");
			if (dataFilter.length > 0) {
				dataValue = dataFilter[0].filterValue;
				var dateParts = dataValue.split("-");

				if (dateParts.length > 0 && dataValue.includes("-")) {
					var date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

					// Obtém o ano da data
					var year = date.getFullYear();

					// Obtém o mês da data
					var month = date.getMonth() + 1; // Adiciona 1 para compensar a base zero

					setCurrentYear(year);
					setCurrentMonth(month);
				}
			}

			const grupoFilter = filters.filter((x) => x.id == "CD_GRUPO");
			if (grupoFilter.length > 0) {
				groupValue = grupoFilter[0].filterValue;
			}

			const stepFilter = filters.filter((x) => x.id == "DS_ETAPA");
			if (stepFilter.length > 0) {
				stepValue = stepFilter[0].filterValue;
			}

			const situationFilter = filters.filter((x) => x.id == "FLG_EXECUCAO");
			if (situationFilter.length > 0) {
				situationValue = situationFilter[0].filterValue;
			}

			if (
				defaultFilterSet &&
				(dataValue || groupValue || stepValue || situationValue)
			)
				fetchData(dataValue, groupValue, stepValue, situationValue);
		} else {
			if (defaultFilters.length > 0) {
				const dataFilter = defaultFilters.filter((x) => x.id == "DATA_INICIAL");
				if (dataFilter.length > 0) {
					dataValue = dataFilter[0].filterValue;
				}

				fetchData(dataValue, groupValue, stepValue, situationValue);
			}
		}
	};

	useEffect(() => {
		handleFilters();
	}, [filters, refreshList, filtersToSearch]);

	useEffect(() => {
		if (filtersToSearch?.length == 0) {
			setCurrentYear(date.getFullYear());
			setCurrentMonth(date.getMonth() + 1);
		}
	}, [filtersToSearch]);

	if (!filtersSet) return <div></div>;

	return (
		<>
			{loading && <Loading />}
			<div>
				<div className="d-flex justify-content-between mb-3">
					<Header
						title="Dashboard"
						buttons={buttons}
						middleButtons={centeredButtons}
						// previousPage={userId ? entityConfig.CLOSING.listTitle : null}
						// previousUrl={() => {
						// 	navigate(`${entity.rootUrl}/Aprovacao`);
						// }}
					/>
				</div>

				<div
					className="table-responsive"
					style={{ height: `${windowHeight - 180}px` }}
				>
					<table className="table">
						<thead>
							<tr className="table-header-cell">{renderHead()}</tr>
							<tr className="table-dashboard-header table-header-cell">
								<th> </th>
								<th style={{ verticalAlign: "middle" }}>Tribunal</th>
								<th style={{ verticalAlign: "middle" }}>Etapa</th>
								{/* <th>Prox. Execução</th> */}

								{renderDays()}
							</tr>
						</thead>
						<tbody>{renderRows()}</tbody>
					</table>
				</div>
			</div>

			{/* Modal detalhes */}
			<Modal
				id="ModalDetalhes"
				show={showModal}
				animation={false}
				onHide={closeModal}
				dialogClassName="modal-90w"
			>
				<Modal.Header
					closeButton={false}
					style={{
						backgroundColor: "#f2f3f8",
					}}
				>
					<div className="d-flex justify-content-between align-items-center w-100">
						<Modal.Title
							style={{
								fontSize: "var(--default-font-size)",
								fontWeight: 700,
							}}
						>
							Visualizando detalhes de processos
						</Modal.Title>
						<div className="d-flex justify-content-end align-items-center">
							<div>
								<Button
									size="sm"
									id="btn-export-csv"
									variant={"primary"}
									onClick={exportToCsv}
									className="icon-button"
									title={"Fechar"}
								>
									<div className="div-button">
										<span className="fa-icon-button">
											<FaFileCsv className="fa-icon-arrow-left" />
										</span>
										<span className="text-button">Exportar para CSV</span>
									</div>
								</Button>
							</div>

							<div>
								<Button
									size="sm"
									id="btn-exclude"
									variant={"primary"}
									onClick={() => showQuestionModal("exclusão")}
									className="icon-button"
									title={"Fechar"}
								>
									<div className="div-button">
										<span className="fa-icon-button">
											<FaTrashAlt className="fa-icon-arrow-left" />
										</span>
										<span className="text-button">Excluir</span>
									</div>
								</Button>
							</div>

							<div>
								<Button
									size="sm"
									id="btn-reprocess"
									variant={"primary"}
									onClick={() => showQuestionModal("reprocessamento")}
									className="icon-button"
									title={"Fechar"}
								>
									<div className="div-button">
										<span className="fa-icon-button">
											<FaRecycle className="fa-icon-arrow-left" />
										</span>
										<span className="text-button">Reprocessar</span>
									</div>
								</Button>
							</div>

							<div>
								<Button
									size="sm"
									id="btn-back"
									variant={"primary"}
									onClick={closeModal}
									className="icon-button"
									title={"Fechar"}
								>
									<div className="div-button">
										<span className="fa-icon-button">
											<FaArrowLeft className="fa-icon-arrow-left" />
										</span>
										<span className="text-button">Voltar</span>
									</div>
								</Button>
							</div>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body
					style={{
						width: modalWidth,
						fontFamily: "helvica neue, roboto, terminal",
						fontSize: "1.5rem",
						overflowY: "scroll",
						maxHeight: `${windowHeight - 180}px`,
					}}
				>
					<div style={{ fontSize: "1.3rem" }}>
						<strong>IDComponenteHistorico:</strong> {selectedRow?.historicComponentId}
						<ul className="list-inline">
							<li className="list-inline-item">
								<strong>Data:</strong>{" "}
								{formatDateTime(new Date(selectedRow?.date))}
							</li>
							<li className="list-inline-item">
								<strong>Grupo:</strong> {selectedRow?.groupCode.toUpperCase()}
							</li>
							<li className="list-inline-item">
								<strong>Etapa:</strong>{" "}
								{selectedRow?.stepDescription.toUpperCase()}
							</li>
							<li className="list-inline-item">
								<strong>Status:</strong>{" "}
								{selectedRow?.statusDescription.toUpperCase()}
							</li>
						</ul>
						<ul className="list-inline">
						{selectedRow?.dtInicio &&<li className="list-inline-item">
								<strong>Início:</strong>
								{formatTime(new Date(selectedRow?.dtInicio))}
							</li>
							}
							{selectedRow?.dtFim &&<li className="list-inline-item">
								<strong>Fim:</strong>
								{formatTime(new Date(selectedRow?.dtFim))}
							</li>
							}

						{selectedRow?.parametro && <>
						<Button variant="secondary" size="sm" onClick={() => {
							navigator.clipboard.writeText(selectedRow?.parametro)
							.then(() => {
							  ToastService.info("Texto copiado para o clipboard!", null);
							})
							.catch(err => {
								ToastService.error("Erro ao copiar o texto: ");
							});
						}} id="btn-no">
							Copiar Parâmetro
						</Button>
						</>}
						</ul>
					</div>

					<ListGroup>
						{modalData.map((item, index) => (
							<ListGroup.Item
								key={index}
								style={{ padding: "1px 10px", fontSize: "1.5rem" }}
							>
								{renderDetail(item.detailType, item.detail, item.detailDate)}
							</ListGroup.Item>
						))}
					</ListGroup>
				</Modal.Body>
			</Modal>

			{/* Modal de confirmações */}
			<Modal
				id="ModalConfirmacao"
				show={questionModal.show}
				animation={false}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header
					style={{
						paddingBottom: 5,
						paddingTop: 10,
						backgroundColor: "#f2f3f8",
					}}
				>
					<Modal.Title
						style={{
							fontSize: "var(--default-font-size)",
							fontWeight: 700,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<span>Confirmação da ação</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						fontSize: "var(--default-font-size)",
						paddingTop: 10,
						paddingBottom: 10,
						backgroundColor: "#f2f3f8",
					}}
				>
					<span>{questionModal.question}</span>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeQuestionModal} id="btn-no">
						NÃO
					</Button>
					<Button
						variant="primary"
						className="btn-save"
						id="btn-yes"
						onClick={async () => questionModal.callback()}
					>
						SIM
					</Button>
				</Modal.Footer>
			</Modal>

			{/* Modal agendamento etapas */}
			<Modal
				id="ModalAgendamento"
				show={scheduleModal.show}
				animation={false}
				onHide={closeScheduleModal}
				size="lg"
				centered
			>
				<Modal.Header
					closeButton={false}
					style={{
						backgroundColor: "#f2f3f8",
					}}
				>
					<div className="d-flex justify-content-between align-items-center w-100">
						<Modal.Title
							style={{
								fontSize: "var(--default-font-size)",
								fontWeight: 700,
							}}
						>
							Agendamento da etapa {scheduleModal.selectedStep}
						</Modal.Title>
						<div className="d-flex justify-content-end align-items-center">
							<div style={{ paddingRight: "10px" }}>
								<Button
									size="sm"
									id="btn-execute-scheduling"
									variant={"primary"}
									disabled={buttonSchedulingDisabled()}
									onClick={async () => saveScheduling()}
									className="icon-button"
									title={"Agendar"}
								>
									<div className="div-button">
										<span className="fa-icon-button">
											<FaClock className="fa-icon-arrow-left" />
										</span>
										<span className="text-button">Agendar</span>
									</div>
								</Button>
							</div>

							<div>
								<Button
									size="sm"
									id="btn-back"
									variant={"primary"}
									onClick={closeScheduleModal}
									className="icon-button"
									title={"Fechar"}
								>
									<div className="div-button">
										<span className="fa-icon-button">
											<FaArrowLeft className="fa-icon-arrow-left" />
										</span>
										<span className="text-button">Voltar</span>
									</div>
								</Button>
							</div>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body
					style={{
						fontFamily: "helvica neue, roboto, terminal",
						fontSize: "1.5rem",
						maxHeight: `${windowHeight - 180}px`,
					}}
				>
					<div
						style={{
							padding: "10px",
							fontFamily: "helvica neue, roboto, terminal",
							fontSize: "1.5rem",
							maxHeight: `${windowHeight - 180}px`,
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "flex-start",
							}}
						>
							<label
								style={{
									fontWeight: "bold",
									marginBottom: "20px",
									paddingRight: "10px",
								}}
							>
								Período:{" "}
							</label>
							<div style={{ paddingRight: "10px" }}>
								<DateRange
									id="dateRange"
									editableDateInputs={true}
									moveRangeOnFirstSelection={false}
									onChange={(item) => setScheduleDates([item.selection])}
									locale={locales["pt"]}
									ranges={scheduleDates}
								/>
							</div>
							<div>
								{scheduleModal.inputFields.length > 0 && (
									<label style={{ fontWeight: "bold" }}>Configurações: </label>
								)}
								{scheduleInputFields.map((field, index) => (
									<React.Fragment key={index}>
										<div className="form-group ">
											<input
												name={field.field}
												id={field.field}
												placeholder={field.field}
												aria-label={field.field}
												type="text"
												className="form-control"
												style={{ width: "300px", marginBottom: "10px" }}
												value={field.value}
												onChange={(e) =>
													handleInputFields(field.field, e.target.value)
												}
											/>
											<label className="label-input-form form-label">
												{field.field}
											</label>
										</div>

										{/* <Form.Control
											type="text"
											id={field}
											style={{ width: "300px", marginBottom: "10px" }}
											placeholder={field}
											aria-label={field}
											size="lg"
											onChange={(e) => handleInputFields(field, e.target.value)}
										/> */}
									</React.Fragment>
								))}
							</div>
						</div>

						<div style={{ marginBottom: "20px" }}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "10px",
								}}
							>
								<label
									style={{
										display: "flex",
										alignItems: "center",
										whiteSpace: "nowrap",
									}}
								>
									Execução imediata
									<input
										id="checkImediate"
										type="checkbox"
										onChange={handleImediate}
										style={{ marginLeft: "10px", marginTop: "-5px" }}
									/>
								</label>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}>
								<label
									style={{
										display: "flex",
										alignItems: "center",
										whiteSpace: "nowrap",
									}}
								>
									Hora Execução:
								</label>
								<input
									id="time"
									onChange={(e) => setScheduleTime(e.target.value)}
									disabled={imediateExecution}
									value={scheduleTime}
									aria-label="Time"
									type="time"
									style={{ marginLeft: "10px", width: "110px" }}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				{/* <Modal.Footer>
					
					<Button
						variant="primary"
						className="btn-save"
						id="btn-yes"
						disabled={buttonSchedulingDisabled()}
						onClick={async () => saveScheduling()}
					>
						SALVAR
					</Button>
				</Modal.Footer> */}
			</Modal>



			{/* Modal de reprocessamento em lote */}
			<Modal
				id="ModalReprocessBatch"
				show={showModalReprocessBatch.show}
				animation={false}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header
					style={{
						paddingBottom: 5,
						paddingTop: 10,
						backgroundColor: "#f2f3f8",
					}}
				>
					<Modal.Title
						style={{
							fontSize: "var(--default-font-size)",
							fontWeight: 700,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<span>Selecionar status a serem reprocessados</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						fontSize: "var(--default-font-size)",
						paddingTop: 10,
						paddingBottom: 10,
						backgroundColor: "#f2f3f8",
					}}
				>


					{showModalReprocessBatch.listStatus.length == 0 
					
					? <span>Nenhum status a ser selecionado!</span>
				
				:<CheckBoxList 
				data={showModalReprocessBatch.listStatus}
				selectedData={showModalReprocessBatch.selectedListStatus}
				setSelectedData={(selected) => {
					setShowModalReprocessBatch({
						...showModalReprocessBatch,
						selectedListStatus: selected as any
					})
				}}
			/>}
					
				

				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModalReprocessBatch} id="btn-no">
						CANCELAR
					</Button>
					<Button
						variant="primary"
						className="btn-save"
						id="btn-yes"
						onClick={reprocessBatch}
						disabled={showModalReprocessBatch.selectedListStatus.length == 0}
					>
						CONFIRMAR
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
