import HttpService from './http.service';
import { ListRequest } from '../models/requestParams.model';

//Somente funções que estão no base controller
export const BaseService = {
  getById,
  getList,
  insert,
  del,
  getExcel,
  sendAttachments,
};

async function getById(
  basePath: string,
  id: string,
  value: any,
  endPointPath: string = '',
  idDataset: string[] = []
) {
  const withFilter = {
    dataset: {
      data: idDataset.map((d) => {
        return { idDataset: d };
      }),
    },
    filter: {
      field: [
        {
          id: id, //nome da coluna de referência que será pesquisada
          value: value,
        },
      ],
    },
  };

  const endPoint = endPointPath || 'GetWithFilter';

  return HttpService.post(`${basePath}/${endPoint}`, withFilter);
}

async function getList(basePath: string, data: ListRequest, endPointPath?: string) {
  const endPoint = endPointPath || 'GetWithFilter';

  return HttpService.post(`${basePath}/${endPoint}`, data);
}

async function insert(basePath: string, data: any) {
  return HttpService.post(`${basePath}/Insert`, data);
}

async function del(basePath: string, data: any) {
  return HttpService.post(`${basePath}/Delete`, data);
}

async function getExcel(basePath: string, data) {
  return HttpService.post(`${basePath}/GetExcel`, data, {
    responseType: 'arraybuffer',
  });
}

async function sendAttachments(basePath: string, data, reqParams: ListRequest) {
  const formData = new FormData();

  data.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  formData.append('reqParams', JSON.stringify(reqParams));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return HttpService.post(`${basePath}/Attachment`, formData, config);
}

export default BaseService;
