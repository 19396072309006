import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-BR', ptBR);

export const CustomDatePicker = ({
  onChangeFunction,
  placeholder = null,
  isRange = false,
  startDate = null,
  endDate = null,
  disabled = false,
}) => {
  const CustomDatePickerInput = ({ value, onClick }) => (
    <input
      readOnly={true}
      value={value}
      onClick={onClick}
      placeholder={placeholder}
      className="custom-datepicker-input"
    />
  );

  return (
    <DatePicker
      calendarClassName="custom-calendar-datepicker"
      wrapperClassName="custom-wrapper-datepicker"
      className="custom-datepicker"
      popperClassName="custom-popper-datepicker"
      dayClassName={() => 'custom-day'}
      weekDayClassName={() => 'custom-header'}
      onChange={(dates) => {
        onChangeFunction(dates);
      }}
      startDate={startDate}
      endDate={endDate}
      dateFormat="dd/MM/yyyy"
      selectsRange={isRange}
      locale="pt-BR"
      isClearable={true}
      showPopperArrow={false}
      showMonthDropdown
      showYearDropdown
      yearDropdownItemNumber={10}
      scrollableYearDropdown
      customInput={<CustomDatePickerInput value onClick />}
      disabled={disabled}
    />
  );
};
