import { Form, Button, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ToastService } from '../../services/toast.service';
import AuthService from '../../services/auth.service';
import { ResetPasswordModel } from '../../models/ResetPasswordModel';

const InvalidLink = (props) => {
  return <p>{props.message}</p>;
};

const ResetPassword = () => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useParams();

  useEffect(() => {
    if (hash) {
      AuthService.isResetPasswordHashValid(hash)
        .then((response) => {
          if (response.result && response.result['dados']) {
            setUserId(response.result['dados'][0].ID_USUARIO);
          } else {
            let errorMessage;

            if (response['return']) {
              errorMessage = response['return']['messageError']['message'];
            } else {
              errorMessage = t('RESET_PASSWORD_INVALID_LINK');
            }

            setError(errorMessage);
          }
        })
        .catch(() => {
          ToastService.error(t('ERROR_GENERIC'));
        })
        .finally(() => setLoading(false));
    }
  }, [hash]);

  const resetPasswordRequest = async (data: ResetPasswordModel) => {
    setLoading(true);

    data.userId = userId;
    try {
      const response = await AuthService.resetPassword(data);
      if (response != null && response['return']['success']) {
        ToastService.success(t('SUCCESS_CHANGE_PERSONAL_DATA'));
        navigate('/');
      } else if (response != null && response.message) {
        ToastService.error(response.message);
      }
    } catch {
      ToastService.error(t('ERROR_GENERIC'));
    }
    setLoading(false);
  };

  const initialValues: ResetPasswordModel = {
    userId: undefined,
    password: '',
    confirmPassword: '',
  };

  const schema = yup.object().shape({
    password: yup.string().required(t('REQUIRED')),
    confirmPassword: yup
      .string()
      .required(t('REQUIRED'))
      .oneOf([yup.ref('password'), null], t('PASSWORD_NOT_MATCH')),
  });

  if (error) {
    return <InvalidLink message={error} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        resetPasswordRequest(values);
      }}
    >
      {({ handleSubmit, handleChange, values, touched, isValid, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>{t('PASSWORD')}</Form.Label>
            <Form.Control
              id="password"
              name="password"
              type="password"
              value={values?.password}
              onChange={handleChange}
              disabled={isLoading}
              isInvalid={touched.password && !!errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>{t('CONFIRM_PASSWORD')}</Form.Label>
            <Form.Control
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              value={values?.confirmPassword}
              onChange={handleChange}
              disabled={isLoading}
              isInvalid={touched.confirmPassword && !!errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col xs={4} sm={4}>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t('SAVE')
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default ResetPassword;
