import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Col,
	Row,
	Image,
	Dropdown,
	Button,
	Modal,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import {
	FaBars,
	FaBell,
	FaRegUserCircle,
	FaRegBuilding,
	FaRegPauseCircle,
	FaPauseCircle,
	FaRegPlayCircle,
	FaPlayCircle,
	FaRegStopCircle,
} from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FilterAutocomplete } from "./filterAutocomplete/FilterAutocomplete";
import { ModalChangePassword } from "./ModalChangePassword";

import { Menu as MenuModel } from "../models/menu.model";
import { StoreModel } from "../models/store.model";

import Logo from "../assets/logo-text.png";
import AuthService from "../services/auth.service";
import { menuMapping, returnCurrentEnterprise } from "../util";
import { ToastService } from "../services/toast.service";
import OrchestratorService from "../services/orchestrator.service";

interface PropsTopBar {
	hideControls?: boolean;
}

export const TopBar = ({ hideControls }: PropsTopBar) => {
	let navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isTopFilterDisabled = useSelector(
		(state: any) => state.isTopFilterDisabled
	);

	const [orchestratorStatus, setOrchestratorStatus] = useState("stoped");

	const [showChangePassword, setShowChangePassword] = useState(false);
	const [showExecutionConfirmModal, SetShowExecutionConfirmModal] =
		useState(false);
	const { menuCollapsed } = useSelector((state: MenuModel) => state);
	const authentication = useSelector(
		(state: StoreModel) => state.authentication
	);
	const companyLogo = useSelector((state: StoreModel) => state.companyLogo);

	const handleCollapsedChange = () => {
		dispatch({ type: "COLLAPSE_CHANGE", menuCollapsed: !menuCollapsed });
	};

	const handleSelectCompany = async (company) => {
		let requestParams = {
			User: (authentication.user = {
				...authentication.user,
				enterpriseId: company?.cnpj,
			}),
		};

		try {
			const apiResponse: any = await AuthService.refreshToken(requestParams);

			if (apiResponse.message === "success") {
				const menuList = menuMapping(apiResponse?.returnData);
				const enterprise = returnCurrentEnterprise(authentication);

				authentication.token = apiResponse.token;

				dispatch({
					type: "SELECT_COMPANY",
					company: {
						companyId: company?.cnpj,
						companyName: company?.name,
						companyLogo: enterprise?.imgLogo,
						companyMenu: menuList,
					},
				});

				navigate("/dash");
			}
		} catch (error) {
			ToastService.error("Erro ao buscar informações da empresa selecionada!");
		}
	};

	const handleShowChangePassword = () => {
		setShowChangePassword(true);
	};

	const handleCloseChangePassword = () => {
		setShowChangePassword(false);
	};

	const Logout = () => {
		dispatch({
			type: "LOGOUT",
		});
		navigate("/Home");
	};

	const handleShowConfirmModal = () => {
		SetShowExecutionConfirmModal(true);
	};

	const handleCloseConfirmModal = () => {
		SetShowExecutionConfirmModal(false);
	};

	const executeProcess = async () => {
		SetShowExecutionConfirmModal(false);

		try {
			await OrchestratorService.executeOrchestrator();
			var status = orchestratorStatus == "stoped" ? "running" : "stoped";
			setOrchestratorStatus(status);	
			if (document.getElementById("").classList.contains("btn.active")){
				document.getElementById("").classList.remove("btn.active");
			}
			ToastService.success("Status do Orquestrador atualizado!");
		} catch (error) {}
	};

	const tooltip = (
		<Tooltip id="tooltip">
			<strong>{`${
				orchestratorStatus == "stoped"
					? "Orquestrador parado. Clique para iniciar"
					: "Orquestrador rodando. Clique para parar"
			}`}</strong>
		</Tooltip>
	);

	useEffect(() => {
		(async () => {
			try {
				var response: any = await OrchestratorService.statusOrchestrator();
				if (response.result) {
					setOrchestratorStatus(response.result);
				}
			} catch {}
		})();
	}, [orchestratorStatus]);

	return (
		<>
			<Row className="top-bar" style={{ marginLeft: 0, marginRight: 0 }}>
				<Col
					xs={4}
					sm={3}
					md={2}
					lg={2}
					xl={3}
					className="d-flex align-items-center"
				>
					<Col
						xs={3}
						sm={3}
						md={2}
						xl={3}
						className="align-items-center justify-content-center"
					>
						{!hideControls && (
							<FaBars
								className="icon-menu"
								onClick={() => handleCollapsedChange()}
								id="btn-hide-controls"
							/>
						)}
					</Col>
					<Col xs={9} sm={8} md={9} lg={6} xl={8}>
						{hideControls ? (
							<Image src={Logo} style={{ width: "150px", maxWidth: "none" }} />
						) : (
							<Link to={"/dash"} id="company-logo-link">
								{/* <Image
                  src={`data:image/png;base64,${companyLogo}`}
                  style={{ width: '150px', maxWidth: 'none' }}
                /> */}
							</Link>
						)}
					</Col>
				</Col>

				<Col
					xs={5}
					sm={7}
					md={6}
					lg={6}
					xl={7}
					className="pl-0"
					id="ColFilterAutocomplete"
				>
					<FilterAutocomplete hide={isTopFilterDisabled} />
				</Col>

				<Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>

				{!hideControls && (
					<Col
						xs={2}
						sm={1}
						md={3}
						xl={1}
						className="d-flex justify-content-end"
					>
						{/* <Button size="sm">
							<div className="div-button">
								<FaRegPauseCircle className="fa-icon" />
								<FaPauseCircle className="fa-icon-hover" />

								<FaRegPlayCircle className="fa-icon" />
								<FaPlayCircle className="fa-icon-hover" />
							</div>
						</Button> */}

						{orchestratorStatus == "stoped" ? (
							<OverlayTrigger placement="bottom" overlay={tooltip}>
								<Dropdown>
									<Dropdown.Toggle
										className="nav-link-top"
										id="btn-status-orchestrator"
										style={{ color: "red" }}
									>
										<FaRegPlayCircle onClick={handleShowConfirmModal} />
									</Dropdown.Toggle>
								</Dropdown>
							</OverlayTrigger>
						) : (
							<OverlayTrigger placement="bottom" overlay={tooltip}>
								<Dropdown>
									<Dropdown.Toggle
										className="nav-link-top"
										id="btn-status-orchestrator"
										style={{ color: "green" }}
									>
										<FaRegStopCircle onClick={handleShowConfirmModal} />
									</Dropdown.Toggle>
								</Dropdown>
							</OverlayTrigger>
						)}

						{/* <Dropdown>
							<Dropdown.Toggle className="nav-link-top" id="dropdown-companies">
								<FaRegBuilding />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{authentication?.enterprises?.map((item, idx) => (
									<Dropdown.Item
										key={idx}
										onClick={() => handleSelectCompany(item)}
									>
										{item?.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown> */}

						<>
							{/* <Dropdown>
								<Dropdown.Toggle
									className="nav-link-top"
									id="dropdown-notifications"
								>
									<FaBell />
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item>Sem notificações no momento</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> */}
							<Dropdown>
								<Dropdown.Toggle
									className="nav-link-top"
									id="dropdown-user-options"
								>
									<FaRegUserCircle />
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => handleShowChangePassword()}
										id="btn-change-password"
									>
										Alterar Senha
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => Logout()}
										id="btn-change-logout"
									>
										{t("LOGOUT")}
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</>
					</Col>
				)}
			</Row>

			<ModalChangePassword
				show={showChangePassword}
				handleClose={handleCloseChangePassword}
				id="modal-change-password"
			/>

			<Modal
				show={showExecutionConfirmModal}
				animation={false}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header
					style={{
						paddingBottom: 5,
						paddingTop: 10,
						backgroundColor: "#f2f3f8",
					}}
				>
					<Modal.Title
						style={{
							fontSize: "var(--default-font-size)",
							fontWeight: 700,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<span>Confirmação da ação</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						fontSize: "var(--default-font-size)",
						paddingTop: 10,
						paddingBottom: 10,
						backgroundColor: "#f2f3f8",
					}}
				>
					<span>Deseja atualizar o status o orquestrador?</span>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseConfirmModal}>
						NÃO
					</Button>
					<Button
						variant="primary"
						className="btn-save"
						onClick={async () => executeProcess()}
					>
						SIM
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
