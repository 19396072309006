import { Form } from "react-bootstrap";

interface IData {
  id: any;
  value: string;
  extraComponent: any;
}
interface IProps {
  setSelectedData: (data: IData) => any;
  selectedData: IData[];
  data: IData[];

  canSelectMultiple?: boolean;
}

export const CheckBoxList = ({ setSelectedData, selectedData, data, canSelectMultiple = true }: IProps) => {
  if (canSelectMultiple === undefined) {
    canSelectMultiple = true;
  }

  return (
    <>
      {data.map((checkData) => {
        let isSelected = selectedData.some((x) => x.id == checkData.id);

        return (
          <>
            <Form.Check
              type="checkbox"
              //   id={formId}
              //   name={formId}
              className="label-checkbox-form"
              //style={styleField}
              style={{ marginTop: "10px" }}
            >
              <Form.Check.Input
                type="checkbox"
                required={false}
                isInvalid={false}
                disabled={false}
                onChange={(e) => {
                    
                  if (!canSelectMultiple) {
                    setSelectedData(checkData);
                  } else {
                    var val;

                    if (selectedData.some((x) => x.id == checkData.id)) {
                      val = selectedData.filter((x) => x.id != checkData.id);
                    } else {
                      val = [...selectedData, checkData];
                    }
                    setSelectedData(val);
                  }
                }}
                value={isSelected ? "selected" : ""}
                checked={isSelected}
              />
              <Form.Check.Label style={{ marginRight: "15px" }}>{checkData.value}</Form.Check.Label>
              {checkData.extraComponent} 
            </Form.Check>
          </>
        );
      })}
    </>
  );
};
