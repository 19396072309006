import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row } from "react-bootstrap";
import { Header } from "../../../components/Header";
import { ButtonModel } from "../../../models/components/button.model";
import { FaArrowLeft, FaSave, FaRegSave } from "react-icons/fa";
import { Formik } from "formik";
import Fieldset from "../../../components/Fieldset";
import { UserModel } from "../../../models/user.model";
import { useDispatch } from "react-redux";
import {
	fieldTypes,
	formMode,
	serviceControllers,
} from "../../../util/enumerators";

import { DynamicForm } from "../../../components/DynamicForm";
import BaseService from "../../../services/base.service";
import { IsValidEmail } from "../../../util";
import { ToastService } from "../../../services/toast.service";
import * as Yup from "yup";

export const UserRegister = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id, mode } = useParams<{ id?: string; mode?: string }>();
	const [isLoading, setLoading] = useState(false);
	const formRef = useRef();

	const [status, setStatus] = useState([
		{ ID_STATUS: "A", DS_STATUS: "Ativo" },
		{ ID_STATUS: "I", DS_STATUS: "Inativo" },
	]);

	const [initialValues, setInitialValues] = useState<UserModel>({
		user: {
			id: 0,
			enterpriseId: 0,
			password: "",
			email: "",
			name: "",
			nickName: "",
			status: "A",
			insertDate: null,
			updateDate: null,
		},
	});

	const buttons: ButtonModel[] = [
		{
			onClick: () => {
				navigate(`/Usuario/`);
			},
			text: "Voltar",
			variant: "outline-primary",
			icon: <FaArrowLeft className="fa-icon-color-red" />,
			tooltip: "Voltar para a página anterior",
		},
		{
			onClick: () => {
				submitFormik();
			},
			text: "Salvar",
			disabled: false,
			hide: mode === formMode.VIEW,
			icon: (
				<>
					<FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
				</>
			),
			tooltip: id ? "Salvar alterações" : "Salvar registro",
		},
	];

	const submitFormik = () => {
		if (formRef && formRef.current) {
			let current: any = formRef.current;
			current.handleSubmit();
		}
	};

	const onSubmit = async (data) => {
		await saveUser(data);
	};

	const fieldList = [
		{
			field: "name",
			label: "Nome",
			placeholder: "Escreva o nome completo",
			lgSize: 4,
			maxLength: 60,
			disabled: isLoading || mode === formMode.VIEW,
		},
		{
			field: "nickName",
			label: "Apelido",
			placeholder: "Escreva o apelido",
			lgSize: 4,
			maxLength: 60,
			disabled: isLoading || mode === formMode.VIEW,
		},
		{
			field: "email",
			label: "Email",
			placeholder: "Ex: exemplo@email.com",
			lgSize: 4,
			maxLength: 60,
			disabled: isLoading || mode === formMode.VIEW || mode === formMode.EDIT,
		},
		{
			field: "status",
			label: "Situação",
			type: fieldTypes.SELECT,
			optionsList: status,
			displayText: "DS_STATUS",
			value: "ID_STATUS",
			lgSize: 4,
			includeSelectOption: false,
			maxLength: 60,
			disabled: isLoading || mode === formMode.VIEW,
		},
	];

	const loadDropDowns = async () => {
		setLoading(true);

		setLoading(false);
	};

	const saveUser = async (data) => {
		setLoading(true);

		const params = {
			id: Number(id) ? parseInt(id) : undefined,
			email: data.user?.email,
			name: data.user?.name.trim(),
			nickName: data.user?.nickName,
			insertDate: data.user?.insertDate ? data.user?.insertDate : new Date(),
			updateDate: data.user?.updateDate,
			status: data.user?.status == "A" ? true : false,
		};

		try {
			BaseService.insert(serviceControllers.USER, params).then(
				(response: any) => {
					setLoading(false);
					if (response.return.success === true) {
						if (params.id > 0) {
							ToastService.success("Usuário atualizado com sucesso!");
						} else {
							ToastService.success("Usuário cadastrado com sucesso!");
						}
					} else {
						ToastService.error(response.return.messageError.message);
					}
				}
			);
		} catch (error) {
			ToastService.error("Erro ao salvar dados do Usuário!");
		}
	};

	const initialize = async () => {
		setLoading(true);

		await Promise.all([loadDropDowns(), loadForEdit()]);

		setLoading(false);
	};

	const loadForEdit = async () => {
		if (id !== undefined || id != null) {
			const apiResponse: any = await BaseService.getById(
				serviceControllers.USER,
				"Id_Usuario",
				id
			);

			if (apiResponse?.return?.success) {
				if (apiResponse.result != null) {
					const data = apiResponse?.result?.dados[0];

					setInitialValues((prevValues) => ({
						...prevValues,
						user: {
							...data,
							id: id,
							email: data?.["Email"],
							name: data?.["Nome"],
							nickName: data?.["Apelido"],
							insertDate: data?.["DT_Inclusao"],
							updateDate: data?.["DT_Alteracao"],
							status: data?.["FlgSituacao"] ? "A" : "I",
						},
					}));
				}
			}
		}
	};

	useEffect(() => {
		dispatch({
			type: "DISABLE_TOP_FILTER",
			isTopFilterDisabled: true,
		});

		dispatch({
			type: "LOAD_FILTERS",
			filtersList: [],
		});

		dispatch({
			type: "SEARCH_FILTERS",
			filtersToSearch: [],
		});

		initialize();
	}, []);

	const validationSchema = Yup.object().shape({
		user: Yup.object().shape({
			name: Yup.string()
				.required("Campo obrigatório")
				.test(
					"nameValid",
					"Neste campo é permitido apenas letras, e com o mínimo de 03",
					function (value) {
						if (value) {
							const nameTrimed = value.trim();
							const pattern = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
							return pattern.test(nameTrimed) && nameTrimed.length > 2;
						} else {
							return true;
						}
					}
				),
			email: Yup.string().test(
				"emailValid",
				"O Email tem que ser válido",
				function (value) {
					return IsValidEmail(value);
				}
			),
		}),
	});

	return (
		<>
			<Header title="Gerenciamento de Usuário" buttons={buttons} />
			<div>
				<Formik
					enableReinitialize={true}
					innerRef={formRef}
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, { resetForm }) => {
						onSubmit(values);
						if (values.user.id == 0)
							//somente sem id
							resetForm();
					}}
				>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						touched,
						isValid,
						errors,
						setValues,
						setFieldValue,
					}) => (
						<Form noValidate onSubmit={handleSubmit}>
							<Fieldset legend="Cadastro Usuário">
								<Row className="align-items-center" style={{ paddingTop: 10 }}>
									<DynamicForm
										form="user"
										fieldList={fieldList}
										touched={touched}
										errors={errors}
										handleChange={handleChange}
										setFieldValue={setFieldValue}
										handleBlur={handleBlur}
										values={values}
									/>
								</Row>
							</Fieldset>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};
