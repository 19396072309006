import axios from 'axios';
import appsettings from '../configs/appsettings.json';
import { ToastService } from './toast.service';
import i18n from '../i18n';
import { store } from '../store/index';

// const showError = true;
let alertSessionExpired = false;

const HttpService = axios.create({
  baseURL: appsettings.API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

function BuildAuth() {
  const state = store.getState();
  const authToken = state?.authentication?.token;
  return authToken;
}

HttpService.interceptors.request.use((config) => {
  let authentication = BuildAuth();
  if (authentication != null) {
    config.headers.authorization = `Bearer ${authentication}`;
  }

  return config;
});

HttpService.interceptors.response.use(
  function (response) {
    (response.data.messages || []).forEach((resultMessage) => {
      if (resultMessage.message) {
        resultMessage.message = resultMessage.message.replaceAll('P0001: ', '');
        const t = i18n.t;
        switch (resultMessage.type) {
          case 0:
          case 'ERROR':
            ToastService.error(t(resultMessage.message));
            break;
          case 1:
          case 'WARNING':
            ToastService.warn(t(resultMessage.message), null);
            break;
          case 2:
          case 'INFO':
            ToastService.info(t(resultMessage.message), null);
            break;
          case 3:
          case 'SUCCESS':
            ToastService.success(t(resultMessage.message));
            break;
          default:
            ToastService.info(t(resultMessage.message), null);
            break;
        }
      }
    });
    if (response.data.redirectRoute) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    if (!response.config.responseType) {
      return response.data;
    } else {
      return response;
    }
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (alertSessionExpired === false) {
        alertSessionExpired = true;
        store.dispatch({
          type: 'LOGOUT',
        });
        ToastService.info('Sua sessão expirou', null);
      }
    } else if (error.response?.data?.error?.Message) {
      const t = i18n.t;
      ToastService.error(t(error.response?.data?.error.Message));
    }
    // else {
    //   ToastService.error(`Erro Genérico: ${error.response?.data?.title} - Contate o administrador`);
    // }

    // if (showError) {
    //   showError = false;
    // }
    // setTimeout(() => {
    //   showError = true;
    // }, 3000);
    return Promise.reject(error);
  }
);

export default HttpService;
