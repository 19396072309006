import { Form, Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import { ToastService } from "../../services/toast.service";
import AuthService from "../../services/auth.service";

import { UserCredentialsModel } from "../../models/userCredentials";

import ForgotPasswordForm from "./forgotPassword";
import { ResultLogin } from "../../models/resultLogin.model";
import { returnCurrentEnterprise } from "../../util";

import { ModalChangePassword } from "../../components/ModalChangePassword";

const Login = () => {
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [email, setEmail] = useState("");
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleDispashLogin = async (response) => {
		let enterprise = returnCurrentEnterprise(response);

		dispatch({ type: "AUTHENTICATE", authentication: response });
		dispatch({
			type: "SELECT_COMPANY",
			company: {
				companyId: enterprise?.cnpj,
				companyName: enterprise?.name,
				companyLogo: enterprise?.imgLogo,
			},
		});
	};

	const saveLoginDataToLocalStorage = (loginData: ResultLogin) => {
		localStorage.setItem("user", JSON.stringify(loginData.user));
		localStorage.setItem("enterprises", JSON.stringify(loginData.enterprises));
		localStorage.setItem("menu", JSON.stringify(loginData.menu));
		localStorage.setItem("token", loginData.token);
		localStorage.setItem("message", loginData.message);
	};

	const loginRequest = async (user: UserCredentialsModel) => {
		setLoading(true);
	
		
		try {
			var response = await AuthService.login(user);

			if (response != null && response?.token != null) {
				saveLoginDataToLocalStorage(response);
				handleDispashLogin(response);

				if (response != null && response?.user?.changePassword === true) {
					const root = document.documentElement;
					root?.style.setProperty("--cor-principal", "#2c304d");
					setEmail(user.email);

					setShowChangePassword(true);
					setLoading(false);
					return;
				}

				navigate("/dash");
			} else if (response != null && response.message) {
				ToastService.error(response.message);
			} else {
				ToastService.error(t("ERROR_GENERIC"));
			}
		} catch {
			ToastService.error(t("ERROR_GENERIC"));
		}
		setLoading(false);
	};

	const handleCloseChangePassword = () => {
		setShowChangePassword(false);
	};

	function showLoginForm() {
		setForgotPassword(false);
	}

	const initialValues: UserCredentialsModel = {
		email: "",
		password: "",
	};

	const schema = yup.object().shape({
		email: yup.string().required(t("REQUIRED")).email(t("INVALID_EMAIL")),
		password: yup.string().required(t("REQUIRED")),
	});

	return forgotPassword ? (
		<>
			<ForgotPasswordForm setForgotPassword={showLoginForm} />
		</>
	) : (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={(values, actions) => {
					loginRequest(values);
				}}
			>
				{({ handleSubmit, handleChange, values, touched, isValid, errors }) => (
					<Form noValidate onSubmit={handleSubmit}>
						<Form.Group className="mb-3">
							<Form.Label>{t("LOGIN")}</Form.Label>
							<Form.Control
								id="email"
								name="email"
								type="text"
								value={values?.email}
								onChange={handleChange}
								disabled={isLoading}
								isInvalid={touched.email && !!errors.email}
							/>
							<Form.Control.Feedback type="invalid" id="invalidEmail">
								{errors.email}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mb-4">
							<Form.Label>{t("PASSWORD")}</Form.Label>
							<Form.Control
								id="password"
								name="password"
								type="password"
								value={values?.password}
								onChange={handleChange}
								disabled={isLoading}
								isInvalid={touched.password && !!errors.password}
							/>
							<Form.Control.Feedback type="invalid" id="invalidPassword">
								{errors.password}
							</Form.Control.Feedback>
						</Form.Group>
						<Row>
							<Col xs={8} sm={8}>
								<Button
									id="forgotPassword"
									variant="link"
									disabled={isLoading}
									onClick={() => {
										setForgotPassword(true);
									}}
								>
									{t("FORGOT_PASSWORD")}
								</Button>
							</Col>
							<Col xs={4} sm={4}>
								<Button
									variant="primary"
									type="submit"
									disabled={isLoading}
									id="signin"
								>
									{isLoading ? (
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									) : (
										t("SIGN_IN")
									)}
								</Button>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
			<ModalChangePassword
				show={showChangePassword}
				handleClose={handleCloseChangePassword}
				id="modal-change-password"
				title={email}
			/>
		</>
	);
};
export default Login;
