import { Spinner } from 'react-bootstrap';
import '../scss/custom.css';

export const Loading = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '-70px',
        left: 0,
        width: '100%',
        height: '100vh',
        background: 'rgba(255, 255, 255, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
      }}
    >
      <Spinner />
    </div>
  );
};
