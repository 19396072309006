import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import LogoPng from '../../assets/images/logo.png';

const Header = () => {
  const [showArrowButton, setShowArrowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 400) {
      setShowArrowButton(true);
    } else {
      setShowArrowButton(false);
    }
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <>
      <footer className="footer-standard-dark bg-extra-dark-gray">
        <div className="footer-widget-area padding-five-tb xs-padding-30px-t">
          <div className="container">
            <div className="equalize xs-equalize-auto">
              <Col
                md={3}
                sm={6}
                xs={12}
                className="widget border-right border-color-medium-dark-gray sm-no-border-right sm-margin-30px-bottom xs-text-center"
              >
                <a href="/" className="margin-20px-bottom display-inline-block">
                  <img className="footer-logo" src={LogoPng} data-rjs={LogoPng} alt="Pofo" />
                </a>
                <p className="text-small width-95 xs-width-100"></p>
                <div className="social-icon-style-8 display-inline-block vertical-align-middle">
                  <ul className="small-icon no-margin-bottom">
                    <li>
                      <a
                        className="linkedin text-white"
                        href="https://www.linkedin.com/company/globalsystem/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="facebook text-white"
                        href="https://www.facebook.com/globalsystembs/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="instagram text-white"
                        href="https://www.instagram.com/globalsystembs/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="twitter text-white"
                        href="https://twitter.com/globalsystembs"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="spotify text-white"
                        href="https://open.spotify.com/user/cases2n3x06c8p1hplfo8cj54?si=1Zng5heiQeGrv1G_MEPB5A"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fab fa-spotify no-margin-right" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                md={3}
                sm={6}
                xs={12}
                className="widget border-right border-color-medium-dark-gray padding-45px-left sm-padding-15px-left sm-no-border-right sm-margin-30px-bottom xs-text-center"
              >
                <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                  Serviços e Soluções
                </div>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-small" href="./solucoes/solution-centre/">
                      Fábrica de Software
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/outsourcing/">
                      Outsourcing e Hunting
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/rpa/">
                      RPA - Robotic Process Automation
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/bpo/">
                      BPO - Business Process Outsourcing
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/BI-e-BA/">
                      BI & BA - Business Intelligence
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/inteligencia-artificial/">
                      IA - Inteligência Artificial
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/web-apps/">
                      App e Web app
                    </a>
                  </li>
                  <li>
                    <a className="text-small" href="./solucoes/e-learning/">
                      E-Learning
                    </a>
                  </li>
                </ul>
              </Col>
              <Col
                md={3}
                sm={6}
                xs={12}
                className="widget border-right border-color-medium-dark-gray padding-45px-left sm-padding-15px-left sm-clear-both sm-no-border-right  xs-margin-30px-bottom xs-text-center"
              >
                <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                  Contato
                </div>
                <p className="text-small display-block margin-15px-bottom width-80 xs-center-col">
                  Av. Jabaquara, 1909, 4º e 5º andares, Saúde - São Paulo.
                </p>
                <div className="text-small">
                  <a href="mailto:relacionamento@gsoftware.com.br">
                    relacionamento@gsoftware.com.br
                  </a>
                </div>
                <div className="text-small">+55 11 2348 2020</div>

                <br />
                <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-10px-bottom font-weight-600">
                  Como chegar
                </div>
                <p>
                  <a
                    className="text-small text-small text-uppercase"
                    href="https://www.google.com.br/maps/place/Global+System+-+Business+Solutions/@-23.6209897,-46.6395009,20z/data=!4m12!1m6!3m5!1s0x94ce5a50d068fdf1:0xdcb6c53cca4e0a23!2sGlobal+System+-+Business+Solutions!8m2!3d-23.6210551!4d-46.6394227!3m4!1s0x94ce5a50d068fdf1:0xdcb6c53cca4e0a23!8m2!3d-23.6210551!4d-46.6394227"
                  >
                    Mapa
                  </a>
                </p>
              </Col>
              <Col
                md={3}
                sm={6}
                xs={12}
                className="widget padding-45px-left sm-padding-15px-left xs-text-center"
              >
                <div className="widget-title alt-font text-small text-medium-gray text-uppercase margin-20px-bottom font-weight-600">
                  Instagram{' '}
                </div>
                <div className="instagram-follow-api">
                  <ul id="instaFeed-footer"></ul>
                </div>
              </Col>
            </div>
          </div>
        </div>

        <div className="bg-dark-footer padding-50px-tb text-center xs-padding-30px-tb">
          <div className="container">
            <Col md={6} sm={6} xs={12} className="text-left text-small xs-text-center">
              &copy; 2021 Global System. All Rights Reserved
            </Col>
            <Col md={6} sm={6} xs={12} className="text-right text-small xs-text-center">
              <a href="termo.html" className="text-dark-gray">
                Termos e Condições / Política de Privacidade
              </a>
            </Col>
          </div>
        </div>
      </footer>

      {showArrowButton && (
        <button
          className="scroll-top-arrow"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          style={{ display: 'inline', fontSize: '14px', border: 'none' }}
        >
          <i className="fal fa-angle-up"></i>Topo
        </button>
      )}
    </>
  );
};
export default Header;
