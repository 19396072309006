import HttpService from "./http.service";

export const OrchestratorService = {
	executeOrchestrator,
	statusOrchestrator,
};

async function statusOrchestrator() {
	return HttpService.get(`/StatusOrchestrator`);
}


async function executeOrchestrator() {
	return HttpService.post(`/ExecuteOrchestrator`);
}

export default OrchestratorService;
