import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { orderByDirections } from '../util/enumerators';

interface IProps {
  orderedColumn?: string;
  direction: string;
  targetColumn: any;
}

export const OrderByArrow = (props: IProps) => {
  const { orderedColumn, direction, targetColumn } = props;

  if (!targetColumn.orderable) return <></>;

  const btnClassName = 'table-sort-button';
  let ascClassName = `${btnClassName}`;
  let descClassName = `${btnClassName}`;

  if (orderedColumn === targetColumn.id && direction === orderByDirections.ASC) {
    ascClassName = `${btnClassName} active`;
  } else if (orderedColumn === targetColumn.id && direction === orderByDirections.DESC) {
    descClassName = `${btnClassName} active`;
  }

  return (
    <div style={{ display: 'inline-block', marginLeft: '3px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FaCaretUp className={ascClassName} />
        <FaCaretDown className={descClassName} />
      </div>
    </div>
  );
};
