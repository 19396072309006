import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyTable } from './EmptyTable';
import { Table } from 'react-bootstrap';
import { DynamicHeader } from './dynamicTable/DynamicHeader';
import { DynamicColumn } from './dynamicTable/DynamicColumn';
import { FaCheck } from 'react-icons/fa';

interface TableComponentProps {
  infiniteScrollClassName: string;
  itemList: {
    items: any[];
  };
  fetchMoreData: () => void;
  totalItems: number;
  gridColumns: any[];
  style?: React.CSSProperties;
  selectedItems?: any[] | any | null;
  selecionarTodos?: any;
  selectItem: (
    itemId: number,
    index: number,
    e: React.MouseEvent,
    lastSelected: number,
    setUltimoSelecionado: any,
    itensSelecionados: any[],
    setItensSelecionados: any,
    referenceList: {
      items: any[];
    }
  ) => void;
  editItem?: (e, index) => void;
  toggleSelecionarTodos?: any;
  ultimoSelecionado?: number;
  setUltimoSelecionado?: any;
  setItensSelecionados?: any;
  isRed?: boolean;
  referenceColumn?: string;
  useIndex?: boolean;
  orderable?: boolean;
  order?: any;
  onColumnOrdered?: (column, direction) => void;
  isLoading?: boolean;
  loadParcelas?: (order, nextPage) => void;
}

/**

ListComponent

@param {string} infiniteScrollClassName - Classe para o componente InfiniteScroll.
@param {object} itemList - Os itens da lista - mostradas na tela.
@param {function} fetchMoreData - Função para buscar mais dados.
@param {number} totalItems - O total de itens.
@param {array} gridColumns - As colunas da tabela.
@param {object} style - OPCIONAL - O estilo do componente.
@param {array|object|null} selectedItems - OPCIONAL - Os itens selecionados (pode ser um array ou um item único).
@param {function} selecionarTodos - OPCIONAL - Função para selecionar todos os itens.
@param {function} selectItem - Função para selecionar um item.
@param {function} editItem - Função para editar um item.
@param {function} toggleSelecionarTodos - OPCIONAL - Função para alternar a seleção de todos os itens.
@param {number} ultimoSelecionado - Classe para o componente InfiniteScroll.

@param {string|null} referenceColumn - OPCIONAL - Coluna usada para poder ser comparada nas funções de seleção.
@param {boolean} useIndex - OPCIONAL - Variável para poder definir se vai usar o index ou não na lógica de seleção dos itens.
@param {boolean} orderable - OPCIONAL - Define se mostrará ou não os ícones para ordenar a tabela
@param {OrderByModel} order - OPCIONAL - objeto contendo informações sobre a ordenação atual
@param {function} onColumnOrdered - OPCIONAL - Função de callback que é executada quando a tabela é ordenada
@param {boolean} isLoading - OPCIONAL - Para o componente saber se ja carregou a tabela inteira ou não

*/

export const TableComponent: React.FC<TableComponentProps> = ({
  infiniteScrollClassName,
  itemList,
  fetchMoreData,
  totalItems,
  gridColumns,
  selectedItems = null,
  selecionarTodos = null,
  selectItem,
  editItem,
  toggleSelecionarTodos = null,
  ultimoSelecionado = null,
  setUltimoSelecionado = null,
  setItensSelecionados = null,
  isRed = false,
  referenceColumn = null,
  useIndex = false,
  orderable = true,
  order = null,
  onColumnOrdered = null,
  isLoading = true,
  //TODO: ajustar logica para aceitar outras funções de GetList com paginação
  loadParcelas,
}) => {
  const [columnsOrderChanged, setColumnsOrderChanged] = useState(0);

  const isSelected = (i, index) => {
    if (!useIndex) {
      if (Array.isArray(selectedItems)) {
        return selectedItems.some((item) => item[referenceColumn] === i[referenceColumn]);
      } else {
        return i[referenceColumn] === selectedItems;
      }
    } else if (!Array.isArray(selectedItems)) {
      return index === selectedItems;
    }
  };

  useEffect(() => {
    setColumnsOrderChanged(columnsOrderChanged + 1);
  }, [order]);

  useEffect(() => {
    const checkSize = async () => {
      if (!isLoading) {
        var infiniteScrollComponent = document.querySelector(
          '.infinite-scroll-component.height-definition-timesheet'
        );
        var tableElement = document.querySelector(
          '.table.table-sm.table-striped.table-bordered.table-hover'
        );

        if (infiniteScrollComponent && tableElement) {
          var infiniteScrollHeight = infiniteScrollComponent.clientHeight;
          var tableHeight = tableElement.clientHeight;

          if (infiniteScrollHeight > tableHeight && itemList.items?.length < totalItems)
            await loadParcelas(null, true);
        }
      }
    };

    checkSize();
  }, [fetchMoreData]);

  return (
    <>
      <InfiniteScroll
        key={columnsOrderChanged}
        className={infiniteScrollClassName}
        dataLength={itemList.items?.length || 0}
        next={fetchMoreData}
        hasMore={itemList.items?.length < totalItems}
        loader={<b>Carregando...</b>}
        height="100%"
      >
        {itemList.items?.length > 0 ? (
          <Table striped bordered hover size="sm">
            <DynamicHeader
              gridColumns={gridColumns}
              selecionarTodos={selecionarTodos}
              toggleSelecionarTodos={toggleSelecionarTodos}
              orderable={orderable}
              order={order}
              onColumnOrdered={(column, direction) => {
                if (onColumnOrdered !== null) {
                  onColumnOrdered(column, direction);
                }
              }}
            />

            <tbody>
              {itemList.items?.map((i, index) => (
                <tr
                  key={`1f-${index}`}
                  id={`table-tr-${index}`}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: isSelected(i, index) && 'var(--cor-principal-background)',
                  }}
                  onClick={(e) => {
                    selectItem(
                      i[referenceColumn],
                      index,
                      e,
                      ultimoSelecionado,
                      setUltimoSelecionado,
                      selectedItems,
                      setItensSelecionados,
                      itemList
                    );
                  }}
                  onDoubleClick={(e) => {
                    if (editItem) editItem(e, index);
                  }}
                >
                  <td
                    style={{
                      padding: 0,
                      width: isSelected(i, index) ? 30 : 5,
                    }}
                  >
                    <div
                      className="line-check"
                      style={{
                        backgroundColor: i.MOC_DIA_UTIL === 0 || isRed ? 'red' : 'green',
                        width: isSelected(i, index) ? '100%' : 9,
                      }}
                    >
                      {isSelected(i, index) && <FaCheck />}
                    </div>
                  </td>

                  <DynamicColumn key={`dc-${index}`} gridColumns={gridColumns} data={i} />
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable />
        )}
      </InfiniteScroll>
    </>
  );
};
