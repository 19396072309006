import { EntityConfig } from '../models/entity/entity.model';

export const entityConfig: EntityConfig = {
	BANK: {
		controller: "/Bank",
		idDataset: "BANCO",
		rootUrl: "/Banco",
		itemKey: "NR_BCO_ID",
		listTitle: "Listagem de Bancos",
	},
	CAPACITY: {
		controller: "/Capacity",
		idDataset: "LOTACAO",
		rootUrl: "/Lotacao",
		itemKey: "NR_LTC_ID",
		listTitle: "Listagem de Lotações",
	},
	CLIENT: {
		controller: "/Client",
		idDataset: "CLIENTE_FORNECEDOR",
		rootUrl: "/Cliente",
		itemKey: "NR_DOC_ID",
		listTitle: "Listagem de Clientes e Fornecedores",
	},
	CLIENT_MANAGER: {
		controller: "/ClientManager",
	},
	CLIENT_RETENTION: {
		controller: "/ClientRetention",
	},
	CLIENT_DEPARTMENT: {
		controller: "/ClientDepartment",
	},
	CLIENT_DEPART_MANAGER: {
		controller: "/ClientDepartManager",
	},
	COLLABORATOR: {
		controller: "/Collaborator",
		idDataset: "COLABORADOR",
		rootUrl: "/Colaborador",
		itemKey: "NR_CLB_ID",
		listTitle: "Listagem de Colaboradores",
	},
	COLLABORATOR_TOKEN: {
		controller: "/CollaboratorToken",
		idDataset: "COLABORADOR_TOKEN",
		rootUrl: "/Token",
		itemKey: "DS_TKN_TOKEN",
	},
	CLOSING: {
		controller: "/Closing",
		idDataset: "FECHAMENTO",
		rootUrl: "/Timesheet",
		itemKey: "NR_CLB_ID",
		listTitle: "Listagem de Fechamento",
	},
	DOMAIN: {
		controller: "/Domain",
	},
	DOMAIN_OWN: {
		controller: "/DomainOwn",
	},
	HOLIDAY: {
		controller: "/Holiday",
		idDataset: "FERIADO",
		rootUrl: "/Feriado",
		itemKey: "DT_FER_DIA",
		listTitle: "Listagem de Feriados",
	},
	MENU: {
		controller: "/Menu",
	},
	PROFILE: {
		controller: "/Profile",
		idDataset: "PERFIL",
		rootUrl: "/Perfil",
		itemKey: "ID_PERFIL",
		listTitle: "Listagem de Perfis",
	},
	PROJECT: {
		controller: "/Project",
		idDataset: "WP_PROJETO",
		rootUrl: "/Project",
		itemKey: "ID_PROJETO",
		listTitle: "Listagem de Projetos",
	},
	PROJECT_BILLING: {
		controller: "/ProjectBilling",
		idDataset: "WP_PROJETO_FATURAMENTO",
		itemKey: "ID_PROJETO_FATURAMENTO",
	},
	REGISTER: { controller: "/Register" },
	REMUNERATION: {
		controller: "/Remuneration",
	},
	SALES_ORDER: {
		controller: "/SalesOrder",
		idDataset: "VENDA",
		rootUrl: "/PedidoVenda",
		itemKey: "NR_TMV_NU",
		listTitle: "Listagem de Pedidos de Venda",
	},
	TIMESHEET: {
		controller: "/Timesheet",
		rootUrl: "/Timesheet",
		itemKey: "DT_PTO_DIA",
	},
	USER: {
		controller: "/User",
		idDataset: "SPR_SEL_USUARIO",
		rootUrl: "/Usuario",
		itemKey: "Id_Usuario",
		listTitle: "Listagem de Usuários",
	},
	VACATION: {
		controller: "/Vacation",
		idDataset: "FERIAS",
		rootUrl: "/Descansos",
		itemKey: "RH_COLABORADOR_NR_CLB_ID",
		listTitle: "Listagem de Descansos",
	},
	PROCESS: {
		controller: "/WorkProcess",
		idDataset: "WP_PROCESSO",
		rootUrl: "/Processo",
		itemKey: "ID_PROCESSO",
		listTitle: "Listagem de Processos",
	},
	PROCESS_LOG: {
		controller: "/WorkProcessLog",
		idDataset: "WP_PROCESSO_LOG",
		rootUrl: "/Logs",
		itemKey: "ID_PROCESSO_LOG",
		listTitle: "Listagem de Logs de Processo",
	},
};
