import { serviceControllers } from "../util/enumerators";
import HttpService from "./http.service";
import { format } from "date-fns";

const basePath = serviceControllers.DASHBOARD;

export const DashboardService = {
	getDashBoard,
	getHistoric,
	getCsv,
	removeDetail,
	reprocessDetail,
	reprocessBatch,
	executeProcess,
	stepScheduling,
};

async function getDashBoard(
	startDate: string,
	groupCode: string,
	stepDescription: string,
	situation: string
) {
	let payload = {};

	if (startDate || groupCode || stepDescription || situation) {
		let adjustedDate = startDate;

		if (adjustedDate) {
			if (adjustedDate?.split("/").length == 2) {
				let dateString = "01/" + adjustedDate.toString();
				let partesData = dateString.split("/");
				let day = Number(partesData[0]);
				let month = Number(partesData[1]);
				let year = Number(partesData[2]);

				let filterDate = new Date(year, month - 1, day); // Meses em JavaScript são indexados a partir de 0

				adjustedDate = filterDate.toISOString();
			}
		}

		payload = {
			startDate: adjustedDate || null,
			stepDescription: stepDescription || null,
			groupCode: groupCode || null,
			situation: situation || null,
		};
	}
	try {
		const resposta = await HttpService.post("/GetDashBoard", payload);
		return resposta;
	} catch (error) {
		console.error("Erro ao obter detalhes:", error);
		throw error;
	}
}

async function getHistoric(id: number) {
	return HttpService.post(`/GetHistoric?HistoricComponentId=${id}`);
}

function getCsv(id: number) {
	HttpService.post(`/GetCSV?HistoricComponentId=${id}`).then((response) => {
		// Adiciona o BOM à string CSV em UTF-8
		const csvContentWithBOM = "\uFEFF" + response;

		// Cria um blob com a string CSV em UTF-8 com BOM
		const blob = new Blob([csvContentWithBOM], {
			type: "text/csv;charset=utf-8",
		});

		// Cria uma URL para o blob
		const url = window.URL.createObjectURL(blob);

		// Cria um link para fazer o download
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", formattedDate() + "_detalhes.csv");

		// Adiciona o link ao corpo do documento e clica nele para iniciar o download
		document.body.appendChild(link);
		link.click();
	});
}

async function removeDetail(id: number) {
	return HttpService.post(`/removeDetail?HistoricComponentId=${id}`);
}

async function reprocessDetail(id: number) {
	return HttpService.post(`/ReprocessDetail?HistoricComponentId=${id}`);
}

async function reprocessBatch(body: {
	historicComponentId: number;
  }[]){
	return HttpService.post(`/ReprocessBatch`, body);
}

async function executeProcess(id: number) {
	return HttpService.post(`/ExecuteProcess?StepComponentId=${id}`);
}

async function stepScheduling(payload: string) {
	try {
		const resposta = await HttpService.post("/StepScheduling", payload);
		return resposta;
	} catch (error) {
		console.error("Erro ao agendar etapa:", error);
		throw error;
	}
}

function formattedDate() {
	const now = new Date();
	const customFormattedDate = format(now, "yyyyMMddHHmmss");
	return customFormattedDate;
}

export default DashboardService;
