import { Button, Modal } from 'react-bootstrap';
import { ButtonModel } from '../models/components/button.model';
import { FaCheck, FaTimes, FaExclamationCircle } from 'react-icons/fa';

interface IProps {
  title?: string;
  show?: boolean;
  handleClose: any;
  handleConfirm: any;
  children?: any;
  style?: any;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationModal = (props: IProps) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false} style={props.style ?? {}}>
      <Modal.Header
        closeButton
        style={{ paddingBottom: 5, paddingTop: 10, backgroundColor: '#f2f3f8' }}
      >
        <Modal.Title style={{ fontSize: 'var(--default-font-size)', fontWeight: 700 }}>
          {props.title ?? 'Deseja realmente excluir este registro?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          fontSize: 'var(--default-font-size)',
          paddingTop: 20,
          paddingBottom: 20,
          backgroundColor: '#f2f3f8',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {/* <FaExclamationCircle
          className="fa-icon-color-red"
          style={{ fontSize: "xxx-large" }}
        /> */}

        {props.children ?? ''}
      </Modal.Body>
      <div className="modal-actions-div" style={{ justifyContent: 'center' }}>
        <div style={{ paddingBottom: 10 }}>
          <>
            <Button
              size="sm"
              onClick={props.handleConfirm}
              className="icon-button"
              title={'Confirmar ação'}
            >
              <div className="div-button" style={{ paddingLeft: 5, paddingRight: 5 }}>
                <span className="fa-icon-button">
                  <FaCheck className="fa-icon-color-green" />
                </span>{' '}
                <span className="text-button">
                  {props.confirmText ? props.confirmText : 'Confirmar'}
                </span>
              </div>
            </Button>
            &nbsp;&nbsp;
          </>
          <>
            <Button
              size="sm"
              onClick={props.handleClose}
              className="icon-button"
              title={'Cancelar ação'}
            >
              <div className="div-button" style={{ paddingLeft: 5, paddingRight: 5 }}>
                <span className="fa-icon-button">
                  <FaTimes className="fa-icon-color-red" />
                </span>{' '}
                <span className="text-button">
                  {props.cancelText ? props.cancelText : 'Cancelar'}
                </span>
              </div>
            </Button>
            &nbsp;&nbsp;
          </>
        </div>
      </div>
    </Modal>
  );
};
