import jsPDF from "jspdf";
import { format } from "date-fns";
import { ListRequest } from "../models/requestParams.model";
import BaseService from "../services/base.service";

export const ExportFunctions = {
	downloadExcel,
	downloadPDF,
};

async function downloadExcel(
	basePath: string,
	dataset: ListRequest,
	idDataset = ""
) {
	let datasetTmp = {
		filter: { ...dataset.filter },
		dataset: { ...dataset.dataset },
	};

	let idx = datasetTmp.dataset?.data?.findIndex(
		(d) => d.idDataset === idDataset
	);

	if (idx !== -1) {
		datasetTmp.dataset.data[idx].pageNumber = -1;
	} else {
		datasetTmp.dataset.data.forEach((dataset) => {
			dataset.pageNumber = -1;
		});
	}
	BaseService.getExcel(basePath, datasetTmp).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", formattedDate() + `${basePath}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
}

async function downloadPDF(htmlId: string) {
	const htmlImage = document.getElementById(htmlId);
	var doc = new jsPDF("l", "mm", "a0");
	doc.setFontSize(9);
	doc.setFont("helvetica", "normal", "400");

	doc.html(htmlImage, {
		callback: async function (doc) {
			doc.save(formattedDate() + "_resumo_fechamento.pdf");
		},
		x: 10,
		y: 10,
		autoPaging: "slice",
		width: htmlImage.clientWidth,
		windowWidth: htmlImage.clientWidth,
	});
}

function formattedDate() {
	const now = new Date();
	const customFormattedDate = format(now, "yyyyMMddHHmmss");
	return customFormattedDate;
}
