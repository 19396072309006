import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import { Menu as MenuModel } from "../models/menu.model";
import { StoreModel } from "../models/store.model";
import { adjustIdName } from "../util";

export const SideNav = () => {
	const [newMenu, setNewMenu] = useState([]);

	const { menuCollapsed } = useSelector((state: MenuModel) => state);
	const authentication = useSelector(
		(state: StoreModel) => state.authentication
	);
	const companyMenu = useSelector((state: StoreModel) => state.companyMenu);

	useEffect((): any => {
		// let menuList = companyMenu;
		let menuList = [
			{
				descriptionMenu: "Dashboard",
				descriptionMenuParent: null,
				id: 126,
				image: "fa-home",
				menuParentId: 0,
				nameIdFieldFromTable: "",
				nameProcUpdate: "",
				ordem: 1,
				perfilId: 1,
				profileMenuId: 0,
				urlTarget: "dash",
			},
			{
				descriptionMenu: "Usuário",
				descriptionMenuParent: null,
				id: 128,
				image: "fa-user-plus",
				menuParentId: 0,
				nameIdFieldFromTable: "",
				nameProcUpdate: "",
				ordem: 1,
				perfilId: 1,
				profileMenuId: 0,
				urlTarget: "Usuario",
			},
		];

		// if (companyMenu === undefined || companyMenu.length === 0) menuList = authentication?.menu;

		const menuParents = menuList?.filter(
			(item) =>
				item.menuParentId === 0 && item.descriptionMenu !== "Menu Interativo"
		);

		const reduceMenu = menuParents?.map((itemParent) => {
			let submenu = [];

			menuList?.map((item) => {
				if (itemParent.id === item.menuParentId) submenu.push(item);
			});

			return {
				...itemParent,
				submenu: submenu,
			};
		});

		setNewMenu(reduceMenu);
	}, [companyMenu]);

	useEffect(() => {
		setTimeout(() => {
			const firstSubMenu = document.querySelector(
				".pro-menu > ul > .pro-menu-item.pro-sub-menu:first-of-type"
			);

			if (firstSubMenu) {
				const popperElement = firstSubMenu.querySelector(
					".pro-inner-list-item.popper-element"
				);

				if (popperElement) {
					const menuItemCount =
						popperElement.querySelectorAll(".pro-menu-item").length;

					if (menuItemCount > 1)
						(popperElement as HTMLElement).style.top = "60px";
				}
			}
		}, 1000);
	}, [, menuCollapsed]);

	return (
		<ProSidebar collapsed={menuCollapsed} className="sidebar-nav" width={249}>
			<Menu iconShape="square">
				{newMenu?.map((itemParent: MenuModel, idx) => {
					if (itemParent.submenu.length > 0) {
						return (
							<SubMenu
								style={{
									fontSize: "var(--default-font-size)",
									zIndex: 9999,
								}}
								key={idx}
								title={itemParent?.descriptionMenu}
								icon={<i className={`fa ${itemParent?.image}`}></i>}
								id={adjustIdName(itemParent?.descriptionMenu)}
							>
								{itemParent?.submenu?.map((item: MenuModel, idx) => (
									<MenuItem
										key={idx}
										style={{
											fontSize: "var(--default-font-size)",
											zIndex: 9999,
										}}
										id={adjustIdName(item?.descriptionMenu)}
									>
										<i className={`fa ${item?.image}`}></i>
										<Link to={`/${item?.urlTarget}`}>
											{item?.descriptionMenu}
										</Link>
									</MenuItem>
								))}
							</SubMenu>
						);
					} else {
						return (
							<MenuItem
								key={idx}
								id={adjustIdName(itemParent?.descriptionMenu)}
								icon={<i className={`fa ${itemParent?.image}`}></i>}
							>
								<Link to={`/${itemParent?.urlTarget}`}>
									{itemParent?.descriptionMenu}
								</Link>
							</MenuItem>
						);
					}
				})}
			</Menu>
		</ProSidebar>
	);
};
