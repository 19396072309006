import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Button, Modal, Col, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { ToastService } from "../services/toast.service";
import AuthService from "../services/auth.service";

import "../scss/custom-button.scss";

export const ModalChangePassword = (props: any) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [isLoading, setLoading] = useState(false);

	const initialValues: any = {
		actualPassword: "",
		newPassword: "",
		passwordConfirmation: "",
	};

	const schema = yup.object().shape({
		actualPassword: yup
			.string()
			.required(t("REQUIRED"))
			.strict(true)
			.trim(t("INVALID_PASSWORD_CHAR")),
		newPassword: yup
			.string()
			.required(t("REQUIRED"))
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
				t("PASSWORD_STRENGTH")
			)
			.strict(true)
			.trim(t("INVALID_PASSWORD_CHAR")),
		passwordConfirmation: yup
			.string()
			.required(t("REQUIRED"))
			.oneOf([yup.ref("newPassword"), null], "As senhas não correspondem"),
	});

	const handleChangePesonalData = (newPassword, actualPassword) => {
		setLoading(true);

		var user = localStorage.getItem("user");
		if (user) {
			try {
				var email = JSON.parse(user).email;

				const payload = {
					email: email,
					newPassword: newPassword,
					actualPassword: actualPassword,
				};

				AuthService.changePassword(payload)
					.then((response: any) => {
						if (response?.return?.success) {
							ToastService.success(t("PASSWORD_CHANGE_SUCCESS"));

							console.log("Senha alterada!");

							if (typeof props.handleClose !== "undefined") {
								props.handleClose();
								navigate("/dash");
							}
						} else {
							const toastOptions = { autoClose: 3000 };
							ToastService.warn(t("PASSWORD_CHANGE_ERROR"), toastOptions);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		// Adicionando um ID ao botão de fechar modal para Automação
		const button = document.querySelector(".btn-close");
		if (button) button.id = "btn-close-modal";
	}, [])
	

	

	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			animation={false}
			size="lg"
		>
			<Formik
				initialValues={initialValues}
				validationSchema={schema}
				onSubmit={(values) => {
					handleChangePesonalData(values.newPassword, values.actualPassword);
				}}
			>
				{({ handleSubmit, handleChange, values, touched, errors }) => (
					<Form noValidate onSubmit={handleSubmit}>
						<Modal.Header closeButton>
							<Modal.Title>{t("CHANGE_PASSWORD")}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row>
								<Col xs={6} sm={6}>
									<Form.Group>
										<Form.Label>{t("ACTUAL_PASSWORD")}</Form.Label>
										<Form.Control
											id="actualPassword"
											name="actualPassword"
											type="password"
											maxLength={15}
											value={values.actualPassword}
											isInvalid={
												!!touched.actualPassword && !!errors.actualPassword
											}
											onChange={handleChange}
											disabled={isLoading}
										/>
										<Form.Control.Feedback
											id="actualPasswordFeedback"
											type="invalid"
											style={{ marginTop: "-15px" }}
										>
											{errors.actualPassword}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col xs={6} sm={6}>
									<Form.Group>
										<Form.Label style={{ marginTop: "10px" }}>
											{t("NEW_PASSWORD")}
										</Form.Label>
										<Form.Control
											id="newPassword"
											name="newPassword"
											type="password"
											maxLength={15}
											value={values.newPassword}
											isInvalid={!!touched.newPassword && !!errors.newPassword}
											onChange={handleChange}
											disabled={isLoading}
										/>
										<Form.Control.Feedback
											id="newPasswordFeedback"
											type="invalid"
											style={{ marginTop: "-15px" }}
										>
											{errors.newPassword}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col xs={6} sm={6}>
									<Form.Group>
										<Form.Label style={{ marginTop: "10px" }}>
											{t("CONFIRM_PASSWORD")}
										</Form.Label>
										<Form.Control
											id="passwordConfirmation"
											name="passwordConfirmation"
											type="password"
											value={values.passwordConfirmation}
											isInvalid={
												!!touched.passwordConfirmation &&
												!!errors.passwordConfirmation
											}
											maxLength={15}
											onChange={handleChange}
											disabled={isLoading}
										/>
										<Form.Control.Feedback
											id="passwordConfirmationFeedback"
											type="invalid"
											style={{ marginTop: "-15px" }}
										>
											{errors.passwordConfirmation}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={props.handleClose}
								id="btn-cancel"
							>
								{t("CANCEL")}
							</Button>
							<Button
								id="btn-save"
								variant="primary"
								className="btn-save"
								type="submit"
								disabled={isLoading}
							>
								{isLoading && (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								)}
								{t("SAVE")}
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};
