import React, { useCallback, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaSearch, FaTimes, FaListUl } from "react-icons/fa";
import { Badge, Form, InputGroup, Dropdown } from "react-bootstrap";
import { ToastService } from "../../services/toast.service";
import { StoreModel } from "../../models/store.model";
import MaskedFormControl from "react-bootstrap-maskedinput";
import Select from "react-select";
import filterTypes from "./FilterEnumerator";
import { formatDate, formatStringToDate } from "../../util";
import { MONTH_YEAR_MASK } from "../../util/consts";

import "react-datepicker/dist/react-datepicker.css";

import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { CustomDatePicker } from "../CustomDatePicker";

export const FilterAutocomplete = ({ hide = false }) => {
	const dispatch = useDispatch();

	const refInput = useRef(null);
	const dropdownRef = useRef(null);
	const badgesDivRef = useRef(null);
	const inputGroupRef = useRef(null);
	const filterButtonDivRef = useRef(null);
	const dropdownMenuRef = useRef<HTMLDivElement>(null);

	const [showBadges, setShowBadges] = useState(true);
	const [showFiltersDiv, setShowFiltersDiv] = useState(false);
	const [tempStyle, setTempStyle] = useState({ marginTop: "5px" });

	const [options, setOptions] = useState([]);
	const [hasSuggestions, setHasSuggestions] = useState<Boolean>(false);
	const [selectedFilter, setSelectedFilter] = useState<any>({});

	const [filterValue, setFilterValue] = useState<any>("");
	const [refValue, setRefValue] = useState<any>({});

	const [focusedFilterIndex, setFocusedFilterIndex] = useState(-1);

	const refreshList = useSelector((state: any) => state.refreshList);

	const appliedFilter = useSelector(
		(state: StoreModel) => state.filtersToSearch
	);

	const optionsListFunction = useSelector(
		(state: StoreModel) => state.optionsListFunction
	);
	const filtersList = useSelector((state: StoreModel) => state.filtersList);
	const [filteredFiltersList, setFilteredFiltersList] = useState(filtersList);
	const [dateRange, setDateRange] = useState([null, null]);

	const forceUpdate = React.useState()[1].bind(null, {});

	const showFiltersColumnStyle = {
		display: "flex",
		marginTop: "5px",
		flexDirection: "column",
		alignItems: "flex-start",
		border: "1px solid rgba(0,0,0,.15)",
		borderRadius: "4px",
		boxShadow: "0 6px 12px rgba(0,0,0,.175)",
		padding: "5px",
	};

	registerLocale("pt-BR", ptBR);

	const showFilters = () => {
		setShowFiltersDiv(true);
		setTempStyle(showFiltersColumnStyle);
		setShowBadges(true);
	};

	const handleResize = () => {
		const { offsetWidth: divInputWidth = 0 } = inputGroupRef?.current || {};
		const { offsetWidth: divFilterWidth = 0, offsetParent } =
			badgesDivRef?.current || {};

		setShowFiltersDiv(false);

		if (offsetParent !== null) {
			setTempStyle({ marginTop: "5px" });
			setShowBadges(divInputWidth >= divFilterWidth);
		} else {
			setShowBadges(true);
			setTempStyle({ marginTop: "-60px" });
		}
	};

	const handleSearchButton = () => {
		const toastOptions = { autoClose: 1000 };
		if (!selectedFilter?.value) {
			dispatch({ type: "REFRESH_LIST", refreshList: !refreshList });
			return;
		}

		const { type: filterDataType, mask: filterMask } = refValue;
		let parsedDate: any = "";
		let filterDisplayValue = "";
		let filterValue = filterTypes.DOMINIO
			? refValue.value
			: refValue.value.trim();

		// Limpar texto, sem caracteres e espaços
		//filterValue = filterValue.replace(/[^\w\s]/gi, "").replace(/\s+/g, "");

		if (
			!refValue.value ||
			((filterDataType === filterTypes.DOMINIO || filterDataType === filterTypes.TEXTO)
			&& filterValue.length < 1)
		) {
			ToastService.warn("Valor do filtro em branco", toastOptions);
			return;
		}

		const alreadyExists = appliedFilter?.find(
			(item) => item.id === selectedFilter?.id
		);

		const validationRegex =
			selectedFilter?.regexValue && new RegExp(selectedFilter?.regexValue);

		const validation = !validationRegex || validationRegex.test(filterValue);

		if (!validation) {
			ToastService.warn(selectedFilter?.messageValidation, toastOptions);
			return;
		}

		
		switch (filterDataType) {
			case filterTypes.INTEIRO:
				filterValue = filterValue.replace(/\D/g, "");
				break;
			case filterTypes.DATA:

			if (!filterValue.includes("/")){
				if (filterValue.length == 6){
					filterValue = filterValue.slice(0, 2) + "/" + filterValue.slice(2);
				}
			}

				if (filterValue.split("/").length === 2) {
					const [month, year] = filterValue.split("/");
					filterValue = `${parseInt(year)}-${month}-01`;
					filterDisplayValue = `${month}/${parseInt(year)}`;
					parsedDate = new Date(
						formatStringToDate(`01/${month}/${parseInt(year)}`)
					);
				} else {
					
					const [day, month, year] = filterValue.split("/");
					filterValue = `${parseInt(year)}-${month}-${day}`;
					filterDisplayValue = `${day}/${month}/${parseInt(year)}`;
					parsedDate = new Date(formatStringToDate(filterDisplayValue));
				}

				if (isNaN(parsedDate.getTime())) {
					ToastService.warn("Data inválida", toastOptions);
					return;
				}
				break;
			case filterTypes.PERIODO:
				if (Array.isArray(filterValue)) {
					parsedDate = new Date(filterValue[1]);

					if (parsedDate.getTime() === 0) {
						ToastService.warn("Selecione duas datas", toastOptions);
						return;
					}

					filterDisplayValue =
						formatDate(filterValue[0]) + " - " + formatDate(filterValue[1]);
					filterValue =
						formatDate(filterValue[0], true) +
						"|" +
						formatDate(filterValue[1], true);
				} else {
					if (filterValue.split("/").length === 2) {
						const [month, year] = filterValue.split("/");
						filterValue = `${parseInt(year)}-${month}-01`;
						filterDisplayValue = `${month}/${parseInt(year)}`;
						parsedDate = new Date(
							formatStringToDate(`01/${month}/${parseInt(year)}`)
						);
					} else {
						const [day, month, year] = filterValue.split("/");
						filterValue = `${parseInt(year)}-${month}-${day}`;
						filterDisplayValue = `${day}/${month}/${parseInt(year)}`;
						parsedDate = new Date(formatStringToDate(filterDisplayValue));
					}

					if (isNaN(parsedDate.getTime())) {
						ToastService.warn("Data inválida", toastOptions);
						return;
					}
				}
				break;
			case filterTypes.DOMINIO:
				filterValue = Array.isArray(filterValue)
					? filterValue.join("|")
					: filterValue.toString();
				break;
		}

		const newFilter = {
			id: selectedFilter?.id,
			filterType: selectedFilter?.value,
			filterValue,
			filterDisplayValue,
			filterMask,
			filterDataType,
			filterDomainOptions: options,
		};

		const newFiltersList = alreadyExists
			? appliedFilter.map((item) =>
					item.id === alreadyExists.id ? newFilter : item
			  )
			: [...appliedFilter, newFilter];

		dispatch({ type: "SEARCH_FILTERS", filtersToSearch: newFiltersList });

		try {
			refInput.current.value = "";
		} catch (error) {}

		refValue.value = '';
		refValue.campoFiltro = undefined
		setFilterValue('')
		setSelectedFilter((prevState) => ({}));
		setDateRange((prevState) => [null, null]);
	};

	const handleSetFilter = async (item, ref) => {
		setFilterValue('')
		setSelectedFilter(item);
		setHasSuggestions(false);

		try {
			ref.current.value = "";
			ref.current.campoFiltro = item.value;
			ref.current.mask = item.mask;
			ref.current.type = item.type;
			ref.current.maxLength =
				item.maxLength != null ? parseInt(item.maxLength) : 100;

			ref.current.focus();
		} catch (error) {}

		refValue.value = "";
		refValue.campoFiltro = item.value;
		refValue.mask = item.mask;
		refValue.type = item.type;
		refValue.maxLength =
			item.maxLength != null ? parseInt(item.maxLength) : 100;

		if (item.type === filterTypes.DOMINIO) {
			if (item.sprAuto && item.sprAuto !== "") {
				let response = await optionsListFunction(
					item.sprAuto,
					item.sprAutoSearch
				);
				let fieldsOption: string[] = item.sprAutoFields.split("|");
				if (fieldsOption.length === 2) {
					if (response.return?.success) {
						setOptions(
							response?.result?.dados?.map((opt) => {
								return {
									id: opt[fieldsOption[0]],
									value: opt[fieldsOption[1]],
								};
							})
						);
					} else {
						setOptions([]);
					}
				} else {
					setOptions([]);
				}
			} else if (item.valueDomain) {
				let options = item?.valueDomain;
				setOptions(options);
			} else {
				setOptions([]);
			}
		}
	};

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (event.target.value.startsWith("@")) {
				refValue.value = event.target.value;
				setFilterValue(event.target.value);
				setHasSuggestions(true);

				if (dropdownRef.current.ariaExpanded !== "true")
					dropdownRef.current.click();

				const filteredList = filtersList?.filter((x) =>
					x.value
						?.toUpperCase()
						.includes(refInput?.current?.value?.toUpperCase())
				);

				setFilteredFiltersList(filteredList);
				forceUpdate();
			} else if (refValue.campoFiltro !== undefined) {
				refValue.value = event.target.value;
				setFilterValue(event.target.value);
				setHasSuggestions(false);
			} else {
				event.preventDefault();
				refValue.value = '';
				setFilterValue('');
				setHasSuggestions(false);
			}
		},
		[setHasSuggestions]
	);

	const handleSelect = useCallback((selected) => {
		if (selectedFilter.component === "multiselect") {
			const selectedValues = selected?.map((option) => option.value);
			refValue.value = selectedValues;
			setFilterValue(selectedValues);
		} else {
			refValue.value = selected.value;
			setFilterValue(selected.value);
		}
	}, []);

	const handleClean = () => {
		dispatch({
			type: "SEARCH_FILTERS",
			filtersToSearch: [],
		});
		setFilterValue("");
		refInput.current.value = "";
	};

	const handleDeleteFilter = (item) => {
		if (!item.isDefault) {
			dispatch({
				type: "SEARCH_FILTERS",
				filtersToSearch: [
					...appliedFilter.filter((itemFilter) => item !== itemFilter),
				],
			});
		}
	};

	const handleKeyUp = (event) => {
		if (
			event.keyCode === 13 &&
			refValue.value !== "" &&
			refInput?.current?.value !== ""
		) {
			handleSearchButton();
		}
	};

	const handleClickFilter = () => {
		setHasSuggestions(true);
		dropdownRef.current.click();
	};

	const handleKeyDown = (event) => {
		const { keyCode } = event;
		switch (keyCode) {
			case 9:
			case 40:
				event.preventDefault();
				setFocusedFilterIndex(
					(prevIndex) => (prevIndex + 1) % filteredFiltersList.length
				);
				break;
			case 38:
				event.preventDefault();
				setFocusedFilterIndex(
					(prevIndex) =>
						(prevIndex - 1 + filteredFiltersList.length) %
						filteredFiltersList.length
				);
				break;
			case 13:
				if (hasSuggestions) {
					event.preventDefault();
					const focusedItem = filteredFiltersList[focusedFilterIndex];
					handleSetFilter(focusedItem, refInput);
				} else {
					handleSearchButton();
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				badgesDivRef.current &&
				!badgesDivRef.current.contains(event.target) &&
				showFiltersDiv &&
				showBadges &&
				!filterButtonDivRef.current.contains(event.target)
			) {
				setTempStyle({ marginTop: "-60px" });
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [showFiltersDiv]);

	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);

		refValue.value = "";
		setSelectedFilter((prevState) => ({}));
	}, []);

	useEffect(() => {
		setFilteredFiltersList(filtersList);
		forceUpdate();
	}, [hasSuggestions]);

	useEffect(() => {
		if (filtersList?.length > 0) {
			handleResize();

			let objectFilterDefault = filtersList?.filter(
				(x) => x.defaultFilterValue != null
			);

			if (appliedFilter?.length === 0) {
				for (let i = 0; i < objectFilterDefault?.length; i++) {
					const element = objectFilterDefault[i];
					let displayValue = null;

					if (element?.type === filterTypes.DATA) {
						const [year, month, day] = element?.defaultFilterValue.split("-");

						displayValue =
							element?.mask === MONTH_YEAR_MASK
								? `${month}/${year}`
								: `${day}/${month}/${year}`;
					}

					appliedFilter.push({
						id: element?.id,
						filterType: element?.value,
						filterValue: displayValue || element?.defaultFilterValue,
						filterDisplayValue: displayValue,
						filterMask: element?.mask,
						filterDataType: element?.type,
						filterDomainOptions: element?.valueDomain,
						isDefault: true,
					});
				}
			}
		}

		setSelectedFilter({});
		forceUpdate();
	}, [filtersList]);

	useEffect(() => {
		const filteredList = filtersList?.filter((x) =>
			x.value?.toUpperCase().includes(refInput?.current?.value?.toUpperCase())
		);

		setFilteredFiltersList(filteredList);
	}, [refInput?.current?.value, filtersList]);

	const handleDateChange = (dates) => {
		refValue.value = dates;
		setDateRange(dates);
	};

	return (
		!hide && (
			<div className="filter-autocomplete">
				<InputGroup size="sm" style={{ display: "flex" }} ref={inputGroupRef}>
					{selectedFilter?.value !== "" && selectedFilter?.value !== undefined && (
						<InputGroup.Text
							id="basic-addon1"
							style={{
								width: "fit-content",
								cursor: "pointer",
								fontWeight: 600,
							}}
							onClick={handleClickFilter}
						>
							{selectedFilter.value}
						</InputGroup.Text>
					)}
					{selectedFilter?.type === filterTypes.DOMINIO ? (
						<Select
							className="select-items"
							classNamePrefix="inner-class-select"
							isMulti={selectedFilter.component === "multiselect"}
							placeholder={"Selecione..."}
							options={options.map((option) => ({
								label: option.value,
								value: option.id,
							}))}
							onKeyDown={handleKeyDown}
							onChange={handleSelect}
							styles={{
								control: (provided) => ({
									width: "100%",
									maxHeight: "26px",
									overflow: "auto",
								}),

								multiValue: (provided) => ({
									maxHeight: "30px",
									overflow: "auto",
								}),
							}}
						/>
					) : selectedFilter?.range ? (
						<CustomDatePicker
							onChangeFunction={handleDateChange}
							placeholder={selectedFilter?.placeholder}
							isRange={true}
							startDate={dateRange[0]}
							endDate={dateRange[1]}
						/>
					) : (
						<Form.Control
							id="filterInput"
							aria-label="Small"
							aria-describedby="inputGroup-sizing-sm"
							placeholder={
								selectedFilter?.value === "" ||
								selectedFilter?.value === undefined
									? "Digite @ para exibir os filtros"
									: selectedFilter?.placeholder
							}
							ref={refInput}
							style={{ width: "auto", borderColor: "lightgray" }}
							mask={selectedFilter?.mask}
							onChange={handleChange}
							onKeyUp={handleKeyUp}
							onKeyDown={handleKeyDown}
							value={filterValue}
							as={selectedFilter?.mask ? MaskedFormControl : "input"}
						/>
					)}
					<InputGroup.Text
						className="ico-search"
						id="filter-search-btn"
						onClick={handleSearchButton}
					>
						<FaSearch />
					</InputGroup.Text>
				</InputGroup>
				<Dropdown>
					<Dropdown.Toggle
						variant="success"
						id="dropdown-toggle-top"
						ref={dropdownRef}
					></Dropdown.Toggle>
					{hasSuggestions && (
						<Dropdown.Menu ref={dropdownMenuRef}>
							{filteredFiltersList?.map((item, idx) => {
								return (
									<Dropdown.Item
										key={idx}
										onClick={() => handleSetFilter(item, refInput)}
										className={idx === focusedFilterIndex ? "focused" : ""}
									>
										{item.value}
									</Dropdown.Item>
								);
							})}
						</Dropdown.Menu>
					)}
				</Dropdown>

				{appliedFilter?.length > 0 && (
					<div
						className={`list-tags ${showBadges ? "visible" : "hidden"}`}
						ref={badgesDivRef}
						style={{ ...tempStyle, backgroundColor: "#FFFFFF", zIndex: 9 }}
					>
						{appliedFilter?.map((item, idx) => {
							let label = "";
							let labelHover = "";

							if (
								item.filterMask !== "" &&
								item.filterDataType === filterTypes.INTEIRO
							) {
								let returnLabel = "";
								let aux = 0;
								let valueArray = item.filterValue?.split("");

								for (let i = 0; i < item.filterMask?.length; i++) {
									const element = item.filterMask[i];

									if (element !== "." && element !== "/" && element !== "-") {
										returnLabel += valueArray[aux];
										aux++;
									} else {
										returnLabel += element;
									}
								}

								label = `${item.filterType}: ${
									returnLabel.length > 15
										? returnLabel.substring(0, 15) + "..."
										: returnLabel
								}`;

								labelHover = `${item.filterType}: ${returnLabel}`;
							} else if (item.filterDataType === filterTypes.DOMINIO) {
								if (item.filterValue[0] !== undefined) {
									let filterValues = [];
									let displayValue = "";

									if (
										item.filterValue.indexOf("|") === -1 &&
										item.filterDomainOptions
									) {
										const option = item.filterDomainOptions.find(
											(opt) => opt.id.toString() === item.filterValue.toString()
										);
										filterValues = [item.filterValue];
										displayValue = option.value;
									} else {
										filterValues = item.filterValue
											.split("|")
											.map((value) => parseInt(value, 10));

										displayValue = filterValues
											.map((value) => {
												const option = options.find(
													(opt) => opt.id.toString() === value.toString()
												);
												return option ? option.value : value;
											})
											.join(", ");
									}

									if (filterValues.length === 1) {
										label = `${item.filterType}: ${
											displayValue?.length > 15
												? displayValue.substring(0, 15) + "..."
												: displayValue
										}`;
									} else {
										label = `${item.filterType}: (${filterValues.length}) selecionados`;
									}

									labelHover = `${item.filterType}: ${displayValue}`;
								}
							} else {
								let displayValue = item.filterDisplayValue || item.filterValue;
								if (displayValue) {
									label = `${item.filterType}: ${
										displayValue.length > 15
											? displayValue.substring(0, 15) + "..."
											: displayValue
									}`;

									labelHover = `${item.filterType}: ${displayValue}`;
								}
							}

							return (
								<Badge
									key={idx}
									bg="primary"
									onClick={() => handleDeleteFilter(item)}
									title={labelHover}
									style={{ marginTop: showFiltersDiv ? "3px" : "0" }}
								>
									{label}
									<FaTimes />
								</Badge>
							);
						})}
						<Badge
							bg="secondary"
							onClick={handleClean}
							id="clean-filter-btn"
							style={{ marginTop: showFiltersDiv ? "3px" : "0" }}
						>
							<FaTimes /> Limpar
						</Badge>
					</div>
				)}
				{appliedFilter?.length > 0 && (
					<div className="list-tags" ref={filterButtonDivRef}>
						<Badge
							bg="primary"
							className="show-filters-button visible"
							onClick={showFilters}
						>
							<FaListUl /> &nbsp; Exibir Filtros
						</Badge>
					</div>
				)}
			</div>
		)
	);
};
